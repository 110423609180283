import React from "react";

import { Spinner, Form, Table, Tabs, Tab } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetClubSettings from "../APP_RESERVATION_FECH_DATA/FechGetClubSettings";
import FechPostEditClubSettings from "../APP_RESERVATION_FECH_DATA/FechPostEditClubSettings";

import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";

import moment from "moment";

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageSettings extends React.Component {
  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  urlValue = "";

  currentOnlineReservationDayPlus = "";
  currentOnlineReservationMaxHours = "";
  currentPaymentApiLogin = "";
  currentPaymentApiPassword = "";
  currentPaymentApiCrc = "";
  currentMaxUserUnPayedOnlineReservation = "";
  currentPaymentApiMerchantId = "";
  currentTrainerReservationDeleteMaxHourBeforeStart = "";
  currentDeleteReservationWithPaymentInClubMaxHourBeforeStart = "";
  deleteReservationCreatedByReception = "";
  deleteReservationCreatedByReceptionMaxHourBeforeStart = "";
  currentDeleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart =
    "";
  currentDeleteReservationWithPaymentByTicketMaxHourBeforeStart = "";
  currentOnlineReservationRegulationsUrl = "";
  currentOnlineReservationRegulationsUrlSquash = "";
  currentOnlineReservationRegulationsUrlPadel = "";
  currentMailOnlineReservationCreate = "";
  currentMailOnlineReservationConfirmPayment = "";
  currentMailOnlineReservationConfirmPaymentAfterDeleteReservation = "";
  currentMailOnlineReservationDeleteUnpaidReservation = "";
  currentDeleteReservationAndReturnReservationPriceToUserTicket = "";
  currentDeleteReservationWithPaymentByTicket = "";
  currentDeleteByUserUnpaidReservationOnline = "";
  currentDeleteReservationWithPaymentInClub = "";

  currentSplitReservationPaymentTennis = "";
  currentSplitReservationPaymentForPhoneNumbersTennis = "";
  currentSplitReservationPaymentHowManyTimeToPayInMinuteTennis = "";
  currentSplitReservationPaymentHowManyPeopleTennis = "";
  currentSplitReservationPaymentTypeTennis = "";

  currentSplitReservationPaymentSquash = "";
  currentSplitReservationPaymentForPhoneNumbersSquash = "";
  currentSplitReservationPaymentHowManyTimeToPayInMinuteSquash = "";
  currentSplitReservationPaymentHowManyPeopleSquash = "";
  currentSplitReservationPaymentTypeSquash = "";

  currentSplitReservationPaymentPadel = "";
  currentSplitReservationPaymentForPhoneNumbersPadel = "";
  currentSplitReservationPaymentHowManyTimeToPayInMinutePadel = "";
  currentSplitReservationPaymentHowManyPeoplePadel = "";
  currentSplitReservationPaymentTypePadel = "";

  deleteReservationCreatedByReception = "";
  deleteReservationCreatedByReceptionMaxHourBeforeStart = "";
  currentTrainerReservationShow = "";
  currentTrainerReservationDelete = "";
  currentReservationOnlinePayedFromUserTicket = "";
  currentReservationOnlinePaidToTicketAndMakeReservation = "";
  currentConnectClientTicketsByPhone = "";
  currentConnectClubAndOnlineClient = "";
  currentShowClubReservationToOnlineClient = "";
  msgToClientWhenReservationWithOnlinePayment = "";
  msgToClientWhenReservationWithOnlinePaymentSquash = "";
  msgToClientWhenReservationWithOnlinePaymentPadel = "";
  currentTicketValueUpdateByUser = "";
  currentTicketValueUpdateByUserMaxValue = "";
  currentTicketValueUpdateByUserMinValue = "";

  currentSplitReservationPaymentTennis = "";
  currentSplitReservationPaymentForPhoneNumbersTennis = "";
  currentSplitReservationPaymentHowManyHoursBeforeReservationTennis = "";
  currentSplitReservationPaymentHowManyTimeToPayInMinuteTennis = "";

  currentSplitReservationPaymentSquash = "";
  currentSplitReservationPaymentForPhoneNumbersSquash = "";
  currentSplitReservationPaymentHowManyHoursBeforeReservationSquash = "";
  currentSplitReservationPaymentHowManyTimeToPayInMinuteSquash = "";

  currentSplitReservationPaymentPadel = "";
  currentSplitReservationPaymentForPhoneNumbersPadel = "";
  currentSplitReservationPaymentHowManyHoursBeforeReservationPadel = "";
  currentSplitReservationPaymentHowManyTimeToPayInMinutePadel = "";

  currentGraphicWholeHourTennis = "";
  currentGraphicWholeHourSquash = "";
  currentGraphicWholeHourPadel = "";
  currentGraphicCheckEmptySpaceTennis = "";
  currentGraphicCheckEmptySpaceSquash = "";
  currentGraphicCheckEmptySpacePadel = "";

  postData = {
    clubReservationWwwStatus: "",
    clubReservationAppStatus: "",
    onlineReservationDayPlus: "",
    onlineReservationMaxHours: "",
    paymentApiLogin: "",
    paymentApiPassword: "",
    paymentApiCrc: "",
    maxUserUnPayedOnlineReservation: "",
    paymentApiMerchantId: "",
    trainerReservationDeleteMaxHourBeforeStart: "",
    deleteReservationWithPaymentInClubMaxHourBeforeStart: "",
    deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart:
      "",
    deleteReservationWithPaymentByTicketMaxHourBeforeStart: "",
    deleteReservationCreatedByReception: "",
    deleteReservationCreatedByReceptionMaxHourBeforeStart: "",
    onlineReservationRegulationsUrl: "",
    onlineReservationRegulationsUrlSquash: "",
    onlineReservationRegulationsUrlPadel: "",
    mailOnlineReservationCreate: "",
    mailOnlineReservationConfirmPayment: "",
    mailOnlineReservationConfirmPaymentAfterDeleteReservation: "",
    mailOnlineReservationDeleteUnpaidReservation: "",
    deleteReservationAndReturnReservationPriceToUserTicket: "",
    deleteReservationWithPaymentByTicket: "",
    deleteByUserUnpaidReservationOnline: "",
    deleteReservationWithPaymentInClub: "",
    trainerReservationShow: "",
    trainerReservationDelete: "",
    reservationOnlinePayedFromUserTicket: "",
    reservationOnlinePaidToTicketAndMakeReservation: "",
    connectClientTicketsByPhone: "",
    connectClubAndOnlineClient: "",
    showClubReservationToOnlineClient: "",
    ticketValueUpdateByUser: "",
    ticketValueUpdateByUserMaxValue: "",
    ticketValueUpdateByUserMinValue: "",

    splitReservationPaymentTennis: "",
    splitReservationPaymentForPhoneNumbersTennis: "",
    splitReservationPaymentHowManyHoursBeforeReservationTennis: "",
    splitReservationPaymentHowManyPeopleTennis: "",
    splitReservationPaymentTypeTennis: "",
    splitReservationPaymentHowManyTimeToPayInMinuteTennis: "",

    splitReservationPaymentSquash: "",
    splitReservationPaymentForPhoneNumbersSquash: "",
    splitReservationPaymentHowManyHoursBeforeReservationSquash: "",
    splitReservationPaymentHowManyPeopleSquash: "",
    splitReservationPaymentTypeSquash: "",
    splitReservationPaymentHowManyTimeToPayInMinuteSquash: "",

    splitReservationPaymentPadel: "",
    splitReservationPaymentForPhoneNumbersPadel: "",
    splitReservationPaymentHowManyHoursBeforeReservationPadel: "",
    splitReservationPaymentHowManyPeoplePadel: "",
    splitReservationPaymentTypePadel: "",
    splitReservationPaymentHowManyTimeToPayInMinutePadel: "",

    graphicWholeHourTennis: "",
    graphicWholeHourSquash: "",
    graphicWholeHourPadel: "",
    graphicCheckEmptySpaceTennis: "",
    graphicCheckEmptySpaceSquash: "",
    graphicCheckEmptySpacePadel: "",
  };

  options = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
  ];

  options48 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
    { value: "34", label: "34" },
    { value: "35", label: "35" },
    { value: "36", label: "36" },
    { value: "37", label: "37" },
    { value: "38", label: "38" },
    { value: "39", label: "39" },
    { value: "40", label: "40" },
    { value: "41", label: "41" },
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "47", label: "47" },
    { value: "48", label: "48" },
  ];

  splitPaymentHowManyPeople = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" }
  ];

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Rezerwację",
      datePickerIsOpen: false,
      previousDay: "",
      refreshReservation: "",
      addCourtPopupForm: "",
      btnEditLoader: false,
      key: "home",
      error: "",
      restClubSettingsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlerUpdateClubSettings = this.handlerUpdateClubSettings.bind(this);
    this.setPostDataValueByDatabase =
      this.setPostDataValueByDatabase.bind(this);
    this.setCurrentValueByDatabase = this.setCurrentValueByDatabase.bind(this);
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want

    this.setState({
      restClubSettingsData: await FechGetClubSettings(
        this.props.match.params.club
      ),
    });

    this.setPostDataValueByDatabase();
    this.setCurrentValueByDatabase();

    this.setState({ btnEditLoader: false });
  }

  setPostDataValueByDatabase() {
    if (this.state.restClubSettingsData.successful) {
      this.postData.clubReservationWwwStatus =
        this.state.restClubSettingsData.data.clubReservationWwwStatus;
      this.postData.clubReservationAppStatus =
        this.state.restClubSettingsData.data.clubReservationAppStatus;
      this.postData.onlineReservationDayPlus =
        this.state.restClubSettingsData.data.onlineReservationDayPlus;
      this.postData.onlineReservationMaxHours =
        this.state.restClubSettingsData.data.onlineReservationMaxHours;
      this.postData.maxUserUnPayedOnlineReservation =
        this.state.restClubSettingsData.data.maxUserUnPayedOnlineReservation;
      this.postData.onlineReservationDayPlus =
        this.state.restClubSettingsData.data.onlineReservationDayPlus;
      this.postData.onlineReservationMaxHours =
        this.state.restClubSettingsData.data.onlineReservationMaxHours;
      this.postData.paymentApiLogin =
        this.state.restClubSettingsData.data.paymentApiLogin;
      this.postData.paymentApiPassword =
        this.state.restClubSettingsData.data.paymentApiPassword;
      this.postData.paymentApiCrc =
        this.state.restClubSettingsData.data.paymentApiCrc;
      this.postData.paymentApiMerchantId =
        this.state.restClubSettingsData.data.paymentApiMerchantId;
      this.postData.trainerReservationDeleteMaxHourBeforeStart =
        this.state.restClubSettingsData.data.trainerReservationDeleteMaxHourBeforeStart;
      this.postData.deleteReservationWithPaymentInClubMaxHourBeforeStart =
        this.state.restClubSettingsData.data.deleteReservationWithPaymentInClubMaxHourBeforeStart;
      this.postData.deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart =
        this.state.restClubSettingsData.data.deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart;
      this.postData.deleteReservationWithPaymentByTicketMaxHourBeforeStart =
        this.state.restClubSettingsData.data.deleteReservationWithPaymentByTicketMaxHourBeforeStart;
      this.postData.onlineReservationRegulationsUrl =
        this.state.restClubSettingsData.data.onlineReservationRegulationsUrl;
      this.postData.onlineReservationRegulationsUrlSquash =
        this.state.restClubSettingsData.data.onlineReservationRegulationsUrlSquash;
      this.postData.onlineReservationRegulationsUrlPadel =
        this.state.restClubSettingsData.data.onlineReservationRegulationsUrlPadel;
      this.postData.mailOnlineReservationCreate =
        this.state.restClubSettingsData.data.mailOnlineReservationCreate;
      this.postData.mailOnlineReservationConfirmPayment =
        this.state.restClubSettingsData.data.mailOnlineReservationConfirmPayment;
      this.postData.mailOnlineReservationConfirmPaymentAfterDeleteReservation =
        this.state.restClubSettingsData.data.mailOnlineReservationConfirmPaymentAfterDeleteReservation;
      this.postData.mailOnlineReservationDeleteUnpaidReservation =
        this.state.restClubSettingsData.data.mailOnlineReservationDeleteUnpaidReservation;
      this.postData.deleteReservationAndReturnReservationPriceToUserTicket =
        this.state.restClubSettingsData.data.deleteReservationAndReturnReservationPriceToUserTicket;
      this.postData.deleteReservationWithPaymentByTicket =
        this.state.restClubSettingsData.data.deleteReservationWithPaymentByTicket;
      this.postData.deleteByUserUnpaidReservationOnline =
        this.state.restClubSettingsData.data.deleteByUserUnpaidReservationOnline;
      this.postData.deleteReservationWithPaymentInClub =
        this.state.restClubSettingsData.data.deleteReservationWithPaymentInClub;
      this.postData.trainerReservationShow =
        this.state.restClubSettingsData.data.trainerReservationShow;
      this.postData.trainerReservationDelete =
        this.state.restClubSettingsData.data.trainerReservationDelete;
      this.postData.reservationOnlinePayedFromUserTicket =
        this.state.restClubSettingsData.data.reservationOnlinePayedFromUserTicket;
      this.postData.reservationOnlinePaidToTicketAndMakeReservation =
        this.state.restClubSettingsData.data.reservationOnlinePaidToTicketAndMakeReservation;
      this.postData.connectClientTicketsByPhone =
        this.state.restClubSettingsData.data.connectClientTicketsByPhone;
      this.postData.connectClubAndOnlineClient =
        this.state.restClubSettingsData.data.connectClubAndOnlineClient;
      this.postData.showClubReservationToOnlineClient =
        this.state.restClubSettingsData.data.showClubReservationToOnlineClient;
      this.postData.deleteReservationCreatedByReception =
        this.state.restClubSettingsData.data.deleteReservationCreatedByReception;
      this.postData.deleteReservationCreatedByReceptionMaxHourBeforeStart =
        this.state.restClubSettingsData.data.deleteReservationCreatedByReceptionMaxHourBeforeStart;
      this.postData.msgToClientWhenReservationWithOnlinePayment =
        this.state.restClubSettingsData.data.msgToClientWhenReservationWithOnlinePayment;
      this.postData.msgToClientWhenReservationWithOnlinePaymentSquash =
        this.state.restClubSettingsData.data.msgToClientWhenReservationWithOnlinePaymentSquash;
      this.postData.msgToClientWhenReservationWithOnlinePaymentPadel =
        this.state.restClubSettingsData.data.msgToClientWhenReservationWithOnlinePaymentPadel;
      this.postData.ticketValueUpdateByUser =
        this.state.restClubSettingsData.data.ticketValueUpdateByUser;
      this.postData.ticketValueUpdateByUserMaxValue =
        this.state.restClubSettingsData.data.ticketValueUpdateByUserMaxValue;
      this.postData.ticketValueUpdateByUserMinValue =
        this.state.restClubSettingsData.data.ticketValueUpdateByUserMinValue;

      this.postData.splitReservationPaymentTennis =
        this.state.restClubSettingsData.data.splitReservationPaymentTennis;
      this.postData.splitReservationPaymentForPhoneNumbersTennis =
        this.state.restClubSettingsData.data.splitReservationPaymentForPhoneNumbersTennis;
      this.postData.splitReservationPaymentHowManyHoursBeforeReservationTennis =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyHoursBeforeReservationTennis;
      this.postData.splitReservationPaymentHowManyPeopleTennis =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyPeopleTennis;
      this.postData.splitReservationPaymentTypeTennis =
        this.state.restClubSettingsData.data.splitReservationPaymentTypeTennis;
      this.postData.splitReservationPaymentHowManyTimeToPayInMinuteTennis =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyTimeToPayInMinuteTennis;

        this.postData.splitReservationPaymentSquash =
        this.state.restClubSettingsData.data.splitReservationPaymentSquash;
      this.postData.splitReservationPaymentForPhoneNumbersSquash =
        this.state.restClubSettingsData.data.splitReservationPaymentForPhoneNumbersSquash;
      this.postData.splitReservationPaymentHowManyHoursBeforeReservationSquash =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyHoursBeforeReservationSquash;
      this.postData.splitReservationPaymentHowManyPeopleSquash =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyPeopleSquash;
      this.postData.splitReservationPaymentTypeSquash =
        this.state.restClubSettingsData.data.splitReservationPaymentTypeSquash;
      this.postData.splitReservationPaymentHowManyTimeToPayInMinuteSquash =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyTimeToPayInMinuteSquash;

        this.postData.splitReservationPaymentPadel =
        this.state.restClubSettingsData.data.splitReservationPaymentPadel;
      this.postData.splitReservationPaymentForPhoneNumbersPadel =
        this.state.restClubSettingsData.data.splitReservationPaymentForPhoneNumbersPadel;
      this.postData.splitReservationPaymentHowManyHoursBeforeReservationPadel =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyHoursBeforeReservationPadel;
      this.postData.splitReservationPaymentHowManyPeoplePadel =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyPeoplePadel;
      this.postData.splitReservationPaymentTypePadel =
        this.state.restClubSettingsData.data.splitReservationPaymentTypePadel;
      this.postData.splitReservationPaymentHowManyTimeToPayInMinutePadel =
        this.state.restClubSettingsData.data.splitReservationPaymentHowManyTimeToPayInMinutePadel;

      this.postData.graphicWholeHourTennis =
        this.state.restClubSettingsData.data.graphicWholeHourTennis;
      this.postData.graphicWholeHourSquash =
        this.state.restClubSettingsData.data.graphicWholeHourSquash;
      this.postData.graphicWholeHourPadel =
        this.state.restClubSettingsData.data.graphicWholeHourPadel;
      this.postData.graphicCheckEmptySpaceTennis =
        this.state.restClubSettingsData.data.graphicCheckEmptySpaceTennis;
      this.postData.graphicCheckEmptySpaceSquash =
        this.state.restClubSettingsData.data.graphicCheckEmptySpaceSquash;
      this.postData.graphicCheckEmptySpacePadel =
        this.state.restClubSettingsData.data.graphicCheckEmptySpacePadel;
    }
  }

  setCurrentValueByDatabase() {
    if (this.state.restClubSettingsData.successful) {
      this.currentOnlineReservationDayPlus =
        this.state.restClubSettingsData.data.onlineReservationDayPlus;
      this.currentOnlineReservationMaxHours =
        this.state.restClubSettingsData.data.onlineReservationMaxHours;
      this.currentPaymentApiLogin =
        this.state.restClubSettingsData.data.paymentApiLogin;
      this.currentPaymentApiPassword =
        this.state.restClubSettingsData.data.paymentApiPassword;
      this.currentPaymentApiCrc =
        this.state.restClubSettingsData.data.paymentApiCrc;
      this.currentMaxUserUnPayedOnlineReservation =
        this.state.restClubSettingsData.data.maxUserUnPayedOnlineReservation;
      this.currentPaymentApiMerchantId =
        this.state.restClubSettingsData.data.paymentApiMerchantId;
      this.currentTrainerReservationDeleteMaxHourBeforeStart =
        this.state.restClubSettingsData.data.trainerReservationDeleteMaxHourBeforeStart;
      this.currentDeleteReservationWithPaymentInClubMaxHourBeforeStart =
        this.state.restClubSettingsData.data.deleteReservationWithPaymentInClubMaxHourBeforeStart;
      this.currentDeleteReservationCreatedByReceptionMaxHourBeforeStart =
        this.state.restClubSettingsData.data.deleteReservationCreatedByReceptionMaxHourBeforeStart;
      this.currentDeleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart =
        this.state.restClubSettingsData.data.deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart;
      this.currentDeleteReservationWithPaymentByTicketMaxHourBeforeStart =
        this.state.restClubSettingsData.data.deleteReservationWithPaymentByTicketMaxHourBeforeStart;
      this.currentOnlineReservationRegulationsUrl =
        this.state.restClubSettingsData.data.onlineReservationRegulationsUrl;
      this.currentOnlineReservationRegulationsUrlSquash =
        this.state.restClubSettingsData.data.onlineReservationRegulationsUrlSquash;
      this.currentOnlineReservationRegulationsUrlPadel =
        this.state.restClubSettingsData.data.onlineReservationRegulationsUrlPadel;
      this.currentMailOnlineReservationCreate =
        this.state.restClubSettingsData.data.mailOnlineReservationCreate;
      this.currentMailOnlineReservationConfirmPayment =
        this.state.restClubSettingsData.data.mailOnlineReservationConfirmPayment;
      this.currentMailOnlineReservationConfirmPaymentAfterDeleteReservation =
        this.state.restClubSettingsData.data.mailOnlineReservationConfirmPaymentAfterDeleteReservation;
      this.currentMailOnlineReservationDeleteUnpaidReservation =
        this.state.restClubSettingsData.data.mailOnlineReservationDeleteUnpaidReservation;
      this.currentMsgToClientWhenReservationWithOnlinePayment =
        this.state.restClubSettingsData.data.msgToClientWhenReservationWithOnlinePayment;
      this.currentMsgToClientWhenReservationWithOnlinePaymentSquash =
        this.state.restClubSettingsData.data.msgToClientWhenReservationWithOnlinePaymentSquash;
      this.currentMsgToClientWhenReservationWithOnlinePaymentPadel =
        this.state.restClubSettingsData.data.msgToClientWhenReservationWithOnlinePaymentPadel;
      this.currentTicketValueUpdateByUserMaxValue =
        this.state.restClubSettingsData.data.ticketValueUpdateByUserMaxValue;
      this.currentTicketValueUpdateByUserMinValue =
        this.state.restClubSettingsData.data.ticketValueUpdateByUserMinValue;
      if (
        this.state.restClubSettingsData.data
          .deleteReservationAndReturnReservationPriceToUserTicket
      ) {
        this.currentDeleteReservationAndReturnReservationPriceToUserTicket =
          "Tak";
      } else {
        this.currentDeleteReservationAndReturnReservationPriceToUserTicket =
          "Nie";
      }
      if (
        this.state.restClubSettingsData.data
          .deleteReservationWithPaymentByTicket
      ) {
        this.currentDeleteReservationWithPaymentByTicket = "Tak";
      } else {
        this.currentDeleteReservationWithPaymentByTicket = "Nie";
      }
      if (
        this.state.restClubSettingsData.data.deleteByUserUnpaidReservationOnline
      ) {
        this.currentDeleteByUserUnpaidReservationOnline = "Tak";
      } else {
        this.currentDeleteByUserUnpaidReservationOnline = "Nie";
      }
      if (
        this.state.restClubSettingsData.data.splitReservationPaymentTennis
      ) {
        this.currentSplitReservationPaymentTennis = "Tak";
      } else {
        this.currentSplitReservationPaymentTennis = "Nie";
      }
      this.currentSplitReservationPaymentForPhoneNumbersTennis = this.state.restClubSettingsData.data.splitReservationPaymentForPhoneNumbersTennis;
      this.currentSplitReservationPaymentHowManyHoursBeforeReservationTennis = this.state.restClubSettingsData.data.splitReservationPaymentHowManyHoursBeforeReservationTennis;
      this.currentSplitReservationPaymentHowManyTimeToPayInMinuteTennis = this.state.restClubSettingsData.data.splitReservationPaymentHowManyTimeToPayInMinuteTennis;
      this.currentSplitReservationPaymentHowManyPeopleTennis = this.state.restClubSettingsData.data.splitReservationPaymentHowManyPeopleTennis;
      if (
        this.state.restClubSettingsData.data.splitReservationPaymentTennis
      ) {
        this.currentSplitReservationPaymentTennis = "Tak";
      } else {
        this.currentSplitReservationPaymentTennis = "Nie";
      }
      this.currentSplitReservationPaymentTypeTennis = this.state.restClubSettingsData.data.splitReservationPaymentTypeTennis;

      if (
        this.state.restClubSettingsData.data.splitReservationPaymentSquash
      ) {
        this.currentSplitReservationPaymentSquash = "Tak";
      } else {
        this.currentSplitReservationPaymentSquash = "Nie";
      }
      this.currentSplitReservationPaymentForPhoneNumbersSquash = this.state.restClubSettingsData.data.splitReservationPaymentForPhoneNumbersSquash;
      this.currentSplitReservationPaymentHowManyHoursBeforeReservationSquash = this.state.restClubSettingsData.data.splitReservationPaymentHowManyHoursBeforeReservationSquash;
      this.currentSplitReservationPaymentHowManyTimeToPayInMinuteSquash = this.state.restClubSettingsData.data.splitReservationPaymentHowManyTimeToPayInMinuteSquash;
      this.currentSplitReservationPaymentHowManyPeopleSquash = this.state.restClubSettingsData.data.splitReservationPaymentHowManyPeopleSquash;
      if (
        this.state.restClubSettingsData.data.splitReservationPaymentSquash
      ) {
        this.currentSplitReservationPaymentSquash = "Tak";
      } else {
        this.currentSplitReservationPaymentSquash = "Nie";
      }
      this.currentSplitReservationPaymentTypeSquash = this.state.restClubSettingsData.data.splitReservationPaymentTypeSquash;

      if (
        this.state.restClubSettingsData.data.splitReservationPaymentPadel
      ) {
        this.currentSplitReservationPaymentPadel = "Tak";
      } else {
        this.currentSplitReservationPaymentPadel = "Nie";
      }
      this.currentSplitReservationPaymentForPhoneNumbersPadel = this.state.restClubSettingsData.data.splitReservationPaymentForPhoneNumbersPadel;
      this.currentSplitReservationPaymentHowManyHoursBeforeReservationPadel = this.state.restClubSettingsData.data.splitReservationPaymentHowManyHoursBeforeReservationPadel;
      this.currentSplitReservationPaymentHowManyTimeToPayInMinutePadel = this.state.restClubSettingsData.data.splitReservationPaymentHowManyTimeToPayInMinutePadel;
      this.currentSplitReservationPaymentHowManyPeoplePadel = this.state.restClubSettingsData.data.splitReservationPaymentHowManyPeoplePadel;
      if (
        this.state.restClubSettingsData.data.splitReservationPaymentPadel
      ) {
        this.currentSplitReservationPaymentPadel = "Tak";
      } else {
        this.currentSplitReservationPaymentPadel = "Nie";
      }
      this.currentSplitReservationPaymentTypePadel = this.state.restClubSettingsData.data.splitReservationPaymentTypePadel;


      if (
        this.state.restClubSettingsData.data.deleteReservationWithPaymentInClub
      ) {
        this.currentDeleteReservationWithPaymentInClub = "Tak";
      } else {
        this.currentDeleteReservationWithPaymentInClub = "Nie";
      }
      if (
        this.state.restClubSettingsData.data.deleteReservationCreatedByReception
      ) {
        this.currentDeleteReservationCreatedByReception = "Tak";
      } else {
        this.currentDeleteReservationCreatedByReception = "Nie";
      }
      if (this.state.restClubSettingsData.data.trainerReservationShow) {
        this.currentTrainerReservationShow = "Tak";
      } else {
        this.currentTrainerReservationShow = "Nie";
      }
      if (this.state.restClubSettingsData.data.trainerReservationDelete) {
        this.currentTrainerReservationDelete = "Tak";
      } else {
        this.currentTrainerReservationDelete = "Nie";
      }
      if (
        this.state.restClubSettingsData.data
          .reservationOnlinePayedFromUserTicket
      ) {
        this.currentReservationOnlinePayedFromUserTicket = "Tak";
      } else {
        this.currentReservationOnlinePayedFromUserTicket = "Nie";
      }
      if (
        this.state.restClubSettingsData.data
          .reservationOnlinePaidToTicketAndMakeReservation
      ) {
        this.currentReservationOnlinePaidToTicketAndMakeReservation = "Tak";
      } else {
        this.currentReservationOnlinePaidToTicketAndMakeReservation = "Nie";
      }
      if (this.state.restClubSettingsData.data.connectClientTicketsByPhone) {
        this.currentConnectClientTicketsByPhone = "Tak";
      } else {
        this.currentConnectClientTicketsByPhone = "Nie";
      }
      if (this.state.restClubSettingsData.data.connectClubAndOnlineClient) {
        this.currentConnectClubAndOnlineClient = "Tak";
      } else {
        this.currentConnectClubAndOnlineClient = "Nie";
      }
      if (
        this.state.restClubSettingsData.data.showClubReservationToOnlineClient
      ) {
        this.currentShowClubReservationToOnlineClient = "Tak";
      } else {
        this.currentShowClubReservationToOnlineClient = "Nie";
      }

      if (this.state.restClubSettingsData.data.ticketValueUpdateByUser) {
        this.currentTicketValueUpdateByUser = "Tak";
      } else {
        this.currentTicketValueUpdateByUser = "Nie";
      }
      if (this.state.restClubSettingsData.data.graphicWholeHourTennis) {
        this.currentGraphicWholeHourTennis = "Tak";
      } else {
        this.currentGraphicWholeHourTennis = "Nie";
      }
      if (this.state.restClubSettingsData.data.graphicWholeHourSquash) {
        this.currentGraphicWholeHourSquash = "Tak";
      } else {
        this.currentGraphicWholeHourSquash = "Nie";
      }
      if (this.state.restClubSettingsData.data.graphicWholeHourPadel) {
        this.currentGraphicWholeHourPadel = "Tak";
      } else {
        this.currentGraphicWholeHourPadel = "Nie";
      }
      if (this.state.restClubSettingsData.data.graphicCheckEmptySpaceTennis) {
        this.currentGraphicCheckEmptySpaceTennis = "Tak";
      } else {
        this.currentGraphicCheckEmptySpaceTennis = "Nie";
      }
      if (this.state.restClubSettingsData.data.graphicCheckEmptySpaceSquash) {
        this.currentGraphicCheckEmptySpaceSquash = "Tak";
      } else {
        this.currentGraphicCheckEmptySpaceSquash = "Nie";
      }
      if (this.state.restClubSettingsData.data.graphicCheckEmptySpacePadel) {
        this.currentGraphicCheckEmptySpacePadel = "Tak";
      } else {
        this.currentGraphicCheckEmptySpacePadel = "Nie";
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === "onlineReservationDayPlus")
      this.postData.onlineReservationDayPlus = value;
    if (name === "onlineReservationMaxHours")
      this.postData.onlineReservationMaxHours = value;
    if (name === "paymentApiLogin") this.postData.paymentApiLogin = value;
    if (name === "paymentApiPassword") this.postData.paymentApiPassword = value;
    if (name === "paymentApiCrc") this.postData.paymentApiCrc = value;
    if (name === "maxUserUnPayedOnlineReservation")
      this.postData.maxUserUnPayedOnlineReservation = value;
    if (name === "paymentApiMerchantId")
      this.postData.paymentApiMerchantId = value;
    if (name === "trainerReservationDeleteMaxHourBeforeStart")
      this.postData.trainerReservationDeleteMaxHourBeforeStart = value;
    if (name === "deleteReservationWithPaymentInClubMaxHourBeforeStart")
      this.postData.deleteReservationWithPaymentInClubMaxHourBeforeStart =
        value;
    if (name === "deleteReservationCreatedByReceptionMaxHourBeforeStart")
      this.postData.deleteReservationCreatedByReceptionMaxHourBeforeStart =
        value;
    if (name === "msgToClientWhenReservationWithOnlinePayment")
      this.postData.msgToClientWhenReservationWithOnlinePayment = value;
    if (name === "msgToClientWhenReservationWithOnlinePaymentSquash")
      this.postData.msgToClientWhenReservationWithOnlinePaymentSquash = value;
    if (name === "msgToClientWhenReservationWithOnlinePaymentPadel")
      this.postData.msgToClientWhenReservationWithOnlinePaymentPadel = value;
    if (
      name ===
      "deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart"
    )
      this.postData.deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart =
        value;
    if (name === "deleteReservationWithPaymentByTicketMaxHourBeforeStart")
      this.postData.deleteReservationWithPaymentByTicketMaxHourBeforeStart =
        value;
    if (name === "onlineReservationRegulationsUrl")
      this.postData.onlineReservationRegulationsUrl = value;
    if (name === "onlineReservationRegulationsUrlSquash")
      this.postData.onlineReservationRegulationsUrlSquash = value;
    if (name === "onlineReservationRegulationsUrlPadel")
      this.postData.onlineReservationRegulationsUrlPadel = value;
    if (name === "mailOnlineReservationCreate")
      this.postData.mailOnlineReservationCreate = value;
    if (name === "mailOnlineReservationConfirmPayment")
      this.postData.mailOnlineReservationConfirmPayment = value;
    if (name === "mailOnlineReservationConfirmPaymentAfterDeleteReservation")
      this.postData.mailOnlineReservationConfirmPaymentAfterDeleteReservation =
        value;
    if (name === "mailOnlineReservationDeleteUnpaidReservation")
      this.postData.mailOnlineReservationDeleteUnpaidReservation = value;
    if (name === "deleteReservationAndReturnReservationPriceToUserTicket")
      this.postData.deleteReservationAndReturnReservationPriceToUserTicket =
        value;
    if (name === "deleteReservationWithPaymentByTicket")
      this.postData.deleteReservationWithPaymentByTicket = value;
    if (name === "deleteByUserUnpaidReservationOnline")
      this.postData.deleteByUserUnpaidReservationOnline = value;
    if (name === "deleteReservationWithPaymentInClub")
      this.postData.deleteReservationWithPaymentInClub = value;
    if (name === "deleteReservationCreatedByReception")
      this.postData.deleteReservationCreatedByReception = value;
    if (name === "msgToClientWhenReservationWithOnlinePayment")
      this.postData.msgToClientWhenReservationWithOnlinePayment = value;
    if (name === "msgToClientWhenReservationWithOnlinePaymentSquash")
      this.postData.msgToClientWhenReservationWithOnlinePaymentSquash = value;
    if (name === "msgToClientWhenReservationWithOnlinePaymentPadel")
      this.postData.msgToClientWhenReservationWithOnlinePaymentPadel = value;
    if (name === "trainerReservationShow")
      this.postData.trainerReservationShow = value;
    if (name === "trainerReservationDelete")
      this.postData.trainerReservationDelete = value;
    if (name === "reservationOnlinePayedFromUserTicket")
      this.postData.reservationOnlinePayedFromUserTicket = value;
    if (name === "reservationOnlinePaidToTicketAndMakeReservation")
      this.postData.reservationOnlinePaidToTicketAndMakeReservation = value;
    if (name === "connectClientTicketsByPhone")
      this.postData.connectClientTicketsByPhone = value;
    if (name === "connectClubAndOnlineClient")
      this.postData.connectClubAndOnlineClient = value;
    if (name === "showClubReservationToOnlineClient")
      this.postData.showClubReservationToOnlineClient = value;
    if (name === "ticketValueUpdateByUser")
      this.postData.ticketValueUpdateByUser = value;
    if (name === "ticketValueUpdateByUserMaxValue") {
      var formattedValue = value.replace(/,/g, ".");
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (regex.test(formattedValue)) {
        this.postData.ticketValueUpdateByUserMaxValue = formattedValue;
      }
    }
    if (name === "ticketValueUpdateByUserMinValue") {
      var formattedValue = value.replace(/,/g, ".");
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (regex.test(formattedValue)) {
        this.postData.ticketValueUpdateByUserMinValue = formattedValue;
      }
    }

    if (name === "splitReservationPaymentTennis")
      this.postData.splitReservationPaymentTennis = value;
    if (name === "splitReservationPaymentForPhoneNumbersTennis")
      this.postData.splitReservationPaymentForPhoneNumbersTennis = value;
    if (name === "splitReservationPaymentHowManyHoursBeforeReservationTennis")
      this.postData.splitReservationPaymentHowManyHoursBeforeReservationTennis = value;
    if (name === "splitReservationPaymentHowManyPeopleTennis")
      this.postData.splitReservationPaymentHowManyPeopleTennis = value;
    if (name === "splitReservationPaymentTypeTennis")
      this.postData.splitReservationPaymentTypeTennis = value;
    if (name === "splitReservationPaymentHowManyTimeToPayInMinuteTennis")
      this.postData.splitReservationPaymentHowManyTimeToPayInMinuteTennis = value;

    if (name === "splitReservationPaymentSquash")
      this.postData.splitReservationPaymentSquash = value;
    if (name === "splitReservationPaymentForPhoneNumbersSquash")
      this.postData.splitReservationPaymentForPhoneNumbersSquash = value;
    if (name === "splitReservationPaymentHowManyHoursBeforeReservationSquash")
      this.postData.splitReservationPaymentHowManyHoursBeforeReservationSquash = value;
    if (name === "splitReservationPaymentHowManyPeopleSquash")
      this.postData.splitReservationPaymentHowManyPeopleSquash = value;
    if (name === "splitReservationPaymentTypeSquash")
      this.postData.splitReservationPaymentTypeSquash = value;
    if (name === "splitReservationPaymentHowManyTimeToPayInMinuteSquash")
      this.postData.splitReservationPaymentHowManyTimeToPayInMinuteSquash = value;

    if (name === "splitReservationPaymentPadel")
      this.postData.splitReservationPaymentPadel = value;
    if (name === "splitReservationPaymentForPhoneNumbersPadel")
      this.postData.splitReservationPaymentForPhoneNumbersPadel = value;
    if (name === "splitReservationPaymentHowManyHoursBeforeReservationPadel")
      this.postData.splitReservationPaymentHowManyHoursBeforeReservationPadel = value;
    if (name === "splitReservationPaymentHowManyPeoplePadel")
      this.postData.splitReservationPaymentHowManyPeoplePadel = value;
    if (name === "splitReservationPaymentTypePadel")
      this.postData.splitReservationPaymentTypePadel = value;
    if (name === "splitReservationPaymentHowManyTimeToPayInMinutePadel")
      this.postData.splitReservationPaymentHowManyTimeToPayInMinutePadel = value;

    if (name === "graphicWholeHourTennis")
      this.postData.graphicWholeHourTennis = value;
    if (name === "graphicWholeHourSquash")
      this.postData.graphicWholeHourSquash = value;
    if (name === "graphicWholeHourPadel")
      this.postData.graphicWholeHourPadel = value;
    if (name === "graphicCheckEmptySpaceTennis")
      this.postData.graphicCheckEmptySpaceTennis = value;
    if (name === "graphicCheckEmptySpaceSquash")
      this.postData.graphicCheckEmptySpaceSquash = value;
    if (name === "graphicCheckEmptySpacePadel")
      this.postData.graphicCheckEmptySpacePadel = value;

    this.setState({ btnEditLoader: false });
  }

  async handlerUpdateClubSettings(value) {
    this.setState({ btnEditLoader: true });
    if (value === "clubReservationWwwStatus")
      this.postData.clubReservationWwwStatus =
        !this.postData.clubReservationWwwStatus;
    if (value === "clubReservationAppStatus")
      this.postData.clubReservationAppStatus =
        !this.postData.clubReservationAppStatus;
    this.setState({
      restClubSettingsData: await FechPostEditClubSettings(
        this.props.match.params.club,
        this.postData
      ),
    });

    this.setPostDataValueByDatabase();
    this.setCurrentValueByDatabase();

    this.setState({ btnEditLoader: false });
  }

  handleChangeSplitReservationPaymentForPhoneNumbersTennis = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    // Walidacja: tylko cyfry i przecinki
    const isValidFormat = /^(\d{9})(,\d{9})*$/.test(value) || value === '';
    this.postData.splitReservationPaymentForPhoneNumbersTennis = value;
    if (isValidFormat) {
      this.setState({ inputValue: value, error: '' });
    } else {
      this.setState({ error: 'Wprowadź numery oddzielone przecinkami, każdy numer musi mieć 9 cyfr.' });
    }
  };

  handleChangeSplitReservationPaymentForPhoneNumbersSquash = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    // Walidacja: tylko cyfry i przecinki
    const isValidFormat = /^(\d{9})(,\d{9})*$/.test(value) || value === '';
    this.postData.splitReservationPaymentForPhoneNumbersSquash = value;
    if (isValidFormat) {
      this.setState({ inputValue: value, error: '' });
    } else {
      this.setState({ error: 'Wprowadź numery oddzielone przecinkami, każdy numer musi mieć 9 cyfr.' });
    }
  };

  handleChangeSplitReservationPaymentForPhoneNumbersPadel = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    // Walidacja: tylko cyfry i przecinki
    const isValidFormat = /^(\d{9})(,\d{9})*$/.test(value) || value === '';
    this.postData.splitReservationPaymentForPhoneNumbersPadel = value;
    if (isValidFormat) {
      this.setState({ inputValue: value, error: '' });
    } else {
      this.setState({ error: 'Wprowadź numery oddzielone przecinkami, każdy numer musi mieć 9 cyfr.' });
    }
  };

  render() {
    var loader = "";
    if (!this.state.restClubSettingsData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
    }

    var btnEditLoaderShow = (
      <div id="loader_div">
        <Spinner animation="border" />
      </div>
    );

    var statusClubReservationAppStatus = "";
    var statusClubReservationWwwStatus = "";
    var editClubReservationAppStatus = "";
    var editClubReservationWwwStatus = "";
    var edit = (
      <button
        type="button"
        class="btn btn-info"
        onClick={() => this.handlerUpdateClubSettings("")}
      >
        Edytuj
      </button>
    );
    // var editOnlineReservationDayPlus = <button type="button" class="btn btn-info" onClick={() => this.handlerUpdateClubSettings('')}>Edytuj</button>;
    // var editOnlineReservationMaxHours = <button type="button" class="btn btn-info" onClick={() => this.handlerUpdateClubSettings('')}>Edytuj</button>;

    // var editPaymentApiLogin = <button type="button" class="btn btn-info" onClick={() => this.handlerUpdateClubSettings('')}>Edytuj</button>;
    // var editPaymentApiPassword = <button type="button" class="btn btn-info" onClick={() => this.handlerUpdateClubSettings('')}>Edytuj</button>;
    // var editPaymentApiCrc = <button type="button" class="btn btn-info" onClick={() => this.handlerUpdateClubSettings('')}>Edytuj</button>;
    // var editMaxUserUnPayedOnlineReservation = <button type="button" class="btn btn-info" onClick={() => this.handlerUpdateClubSettings('')}>Edytuj</button>;
    // var editPaymentApiMerchantId = <button type="button" class="btn btn-info" onClick={() => this.handlerUpdateClubSettings('')}>Edytuj</button>;
    // var editOnlineReservationRegulationsUrl = <button type="button" class="btn btn-info" onClick={() => this.handlerUpdateClubSettings('')}>Edytuj</button>;

    if (this.state.restClubSettingsData.successful) {
      if (
        this.state.restClubSettingsData.data.clubReservationWwwStatus === true
      ) {
        statusClubReservationWwwStatus = (
          <span class="badge badge-success">Włączone</span>
        );
        if (this.state.btnEditLoader) {
        } else {
          editClubReservationWwwStatus = (
            <button
              type="button"
              class="btn btn-danger"
              onClick={() =>
                this.handlerUpdateClubSettings("clubReservationWwwStatus")
              }
            >
              Wyłącz rezerwację
            </button>
          );
        }
      } else {
        statusClubReservationWwwStatus = (
          <span class="badge badge-danger">Wyłączone</span>
        );
        if (this.state.btnEditLoader) {
        } else {
          editClubReservationWwwStatus = (
            <button
              type="button"
              class="btn btn-success"
              onClick={() =>
                this.handlerUpdateClubSettings("clubReservationWwwStatus")
              }
            >
              Włącz rezerwację
            </button>
          );
        }
      }
      if (
        this.state.restClubSettingsData.data.clubReservationAppStatus === true
      ) {
        statusClubReservationAppStatus = (
          <span class="badge badge-success">Włączone</span>
        );
        if (this.state.btnEditLoader) {
        } else {
          editClubReservationAppStatus = (
            <button
              type="button"
              class="btn btn-danger"
              onClick={() =>
                this.handlerUpdateClubSettings("clubReservationAppStatus")
              }
            >
              Wyłącz rezerwację
            </button>
          );
        }
      } else {
        statusClubReservationAppStatus = (
          <span class="badge badge-danger">Wyłączone</span>
        );
        if (this.state.btnEditLoader) {
        } else {
          editClubReservationAppStatus = (
            <button
              type="button"
              class="btn btn-success"
              onClick={() =>
                this.handlerUpdateClubSettings("clubReservationAppStatus")
              }
            >
              Włącz rezerwację
            </button>
          );
        }
      }

      loader = (
        <>
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.key}
            onSelect={(k) => this.setState({ key: k })}
          >
            <Tab eventKey="home" title="Rezerwacje Online">
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        Ustawienia ogólne rezerwacji online
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Rezerwacje online - Strona www:</td>
                    <td>{statusClubReservationWwwStatus}</td>
                    <td></td>
                    <td>
                      {this.state.btnEditLoader
                        ? btnEditLoaderShow
                        : editClubReservationWwwStatus}
                    </td>
                  </tr>
                  <tr>
                    <td>Rezerwacje online - Aplikacja Mobilna:</td>
                    <td>{statusClubReservationAppStatus}</td>
                    <td></td>
                    <td>
                      {this.state.btnEditLoader
                        ? btnEditLoaderShow
                        : editClubReservationAppStatus}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ile dni do przodu możliwa rezerwacja. <br />
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentOnlineReservationDayPlus}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={this.postData.onlineReservationDayPlus}
                        id="onlineReservationDayPlus"
                        name="onlineReservationDayPlus"
                        onChange={this.handleChange}
                      >
                        {/* <option>{this.courtDefaultValue}</option>*/}
                        {this.options.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Maksymalna długość jednej rezerwacji online (w godzinach).
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentOnlineReservationMaxHours}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={this.postData.onlineReservationMaxHours}
                        id="onlineReservationMaxHours"
                        name="onlineReservationMaxHours"
                        onChange={this.handleChange}
                      >
                        {/* <option>{this.courtDefaultValue}</option>*/}
                        {this.options.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Maksymalna liczba nieopłaconych rezerwacji Online na
                      użytkownika.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentMaxUserUnPayedOnlineReservation}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={this.postData.maxUserUnPayedOnlineReservation}
                        id="maxUserUnPayedOnlineReservation"
                        name="maxUserUnPayedOnlineReservation"
                        onChange={this.handleChange}
                      >
                        {/* <option>{this.courtDefaultValue}</option>*/}
                        {this.options.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Link do regulaminu. [TENIS]</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentOnlineReservationRegulationsUrl}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="onlineReservationRegulationsUrl"
                        name="onlineReservationRegulationsUrl"
                        // disabled={this.props.trainer}
                        // value={this.currentOnlineReservationRegulationsUrl || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Link do regulaminu. [SQUASH]</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentOnlineReservationRegulationsUrlSquash}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="onlineReservationRegulationsUrlSquash"
                        name="onlineReservationRegulationsUrlSquash"
                        // disabled={this.props.trainer}
                        // value={this.currentOnlineReservationRegulationsUrlSquash || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Link do regulaminu. [PADEL]</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentOnlineReservationRegulationsUrlPadel}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="onlineReservationRegulationsUrlPadel"
                        name="onlineReservationRegulationsUrlPadel"
                        // disabled={this.props.trainer}
                        // value={this.currentOnlineReservationRegulationsUrlPadel || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      TENIS Możliwość rezerwacji online tylko o pełnych godzinach.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentGraphicWholeHourTennis}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.graphicWholeHourTennis}
                        id="graphicWholeHourTennis"
                        // disabled="false"
                        name="graphicWholeHourTennis"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      TENIS Sprawdzaj czy nowa rezerwacja online nie spowoduje powstania luki pół godzinnej na grafiku.*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentGraphicCheckEmptySpaceTennis}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.graphicCheckEmptySpaceTennis}
                        id="graphicCheckEmptySpaceTennis"
                        // disabled="false"
                        name="graphicCheckEmptySpaceTennis"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      SQUASH Możliwość rezerwacji online tylko o pełnych godzinach.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentGraphicWholeHourSquash}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.graphicWholeHourSquash}
                        id="graphicWholeHourSquash"
                        // disabled="false"
                        name="graphicWholeHourSquash"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                    SQUASH Sprawdzaj czy nowa rezerwacja online nie spowoduje powstania luki pół godzinnej na grafiku.*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentGraphicCheckEmptySpaceSquash}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.graphicCheckEmptySpaceSquash}
                        id="graphicCheckEmptySpaceSquash"
                        // disabled="false"
                        name="graphicCheckEmptySpaceSquash"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      PADEL Możliwość rezerwacji online tylko o pełnych godzinach.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentGraphicWholeHourPadel}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.graphicWholeHourPadel}
                        id="graphicWholeHourPadel"
                        // disabled="false"
                        name="graphicWholeHourPadel"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                    PADEL Sprawdzaj czy nowa rezerwacja online nie spowoduje powstania luki pół godzinnej na grafiku.*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentGraphicCheckEmptySpacePadel}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.graphicCheckEmptySpacePadel}
                        id="graphicCheckEmptySpacePadel"
                        // disabled="false"
                        name="graphicCheckEmptySpacePadel"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Jeżeli opcja jest uruchomiona system będzie sprawdzał czy podczas rezerwacji online nowa rezerwacja nie spowoduje
                  powstania luki pół godzinnej przed oraz po rezerwacji co zablokowało by możliwośc kolejnej rezerwacji.
                  Opcja działa tylko w przypadku gdy damy możlwiość rezerwacji nie tylko w pełnych godzinach.
                  <br />
                </font>
              </Table>

              <br />
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        Automatyczne łączenie klientów klubowych oraz
                        internetowych po numerze telefonu
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Czy połączyć klientów klubowych z tymi którzy założyli
                      konto przez internet*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentConnectClubAndOnlineClient}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.connectClubAndOnlineClient}
                        id="connectClubAndOnlineClient"
                        // disabled="false"
                        name="connectClubAndOnlineClient"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Czy klinet z www może zobaczyć w aplikacji rezerwację
                      utworzonę przez recepcję**
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentShowClubReservationToOnlineClient}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.showClubReservationToOnlineClient}
                        id="showClubReservationToOnlineClient"
                        //disabled="false"
                        name="showClubReservationToOnlineClient"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Połączenie klientów oznacza, że w systemie nie bedą
                  zdublowane osoby przypisane do tego samego numeru telefonu.
                  <br />
                  Przykładowo jeśli w systemie istnieje konto Jan Kowalski tel.
                  666555444 utworzonę przez recepcję a przez intenet klient
                  utworzy konto z takim samy numerem telefonu na portalu
                  RezerwujKort.pl lub w aplikacji mobilne i potwierdzi numer
                  telefonu przez sms który zostanie wysłany przez systemie a
                  następnie zrobi rezerwację w Twoim klubie wtedy konta zostaną
                  automatycznie połączone.
                  <br />
                  Oznacza to, że przy tworzeniu rezerwacji będzie tylko jedno
                  konto z numerem 666555444 i danymi które użytkownik podał
                  zakładając konto.
                  <br />W historii rezerwacji i innych panelach po wybraniu
                  użytkownika z numerem 666555444 zobaczysz wszystkie rezerwację
                  przypisane do konta które było utworzone przez recepcję oraz
                  do konta utworzonego przez użytkownika.
                </font>
                <br />
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  ** Jeżeli opcja będzie zaznaczona na tak oraz konta będą
                  połączone klient w aplikacji będzie widział nie tylko
                  rezerwację które sam zrobił przez stronę www lub aplikację,
                  ale również te utworzone przez recepcję przypisane do jego
                  numeru telefonu.
                </font>
              </Table>

              <br />
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        Rezerwacje - Płatność online
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Czy klienci mogą anulować nieopłacone rezerwacje online
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentDeleteByUserUnpaidReservationOnline}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.deleteByUserUnpaidReservationOnline
                        }
                        id="deleteByUserUnpaidReservationOnline"
                        // disabled="false"
                        name="deleteByUserUnpaidReservationOnline"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  
                  <tr>
                    <td>
                      Czy klienci mogą anulować opłacone rezerwacje online i
                      otrzymać zwrot środków na karnet*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentDeleteReservationAndReturnReservationPriceToUserTicket
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData
                            .deleteReservationAndReturnReservationPriceToUserTicket
                        }
                        id="deleteReservationAndReturnReservationPriceToUserTicket"
                        // disabled="false"
                        name="deleteReservationAndReturnReservationPriceToUserTicket"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ile godziny przed rozpoczęciem klient może usunąć
                      rezerwację
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentDeleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart
                        }
                        id="deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart"
                        name="deleteReservationAndReturnReservationPriceToUserTicketMaxHourBeforeStart"
                        onChange={this.handleChange}
                      >
                        {this.options48.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Klient będzie miał możliwość usunięcia opłaconej . Jeśli
                  karnet nie istnieje (lub istniejące nie są już aktywne)
                  utworzony zostanie nowy
                </font>
              </Table>

              <br />
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        Rezerwacje - Płatność karnetem
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Czy klienci mogą anulować rezerwacje opłacone karnetem*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentDeleteReservationWithPaymentByTicket}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.deleteReservationWithPaymentByTicket
                        }
                        id="deleteReservationWithPaymentByTicket"
                        // disabled="false"
                        name="deleteReservationWithPaymentByTicket"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ile godziny przed rozpoczęciem klient może usunąć
                      rezerwację
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentDeleteReservationWithPaymentByTicketMaxHourBeforeStart
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .deleteReservationWithPaymentByTicketMaxHourBeforeStart
                        }
                        id="deleteReservationWithPaymentByTicketMaxHourBeforeStart"
                        name="deleteReservationWithPaymentByTicketMaxHourBeforeStart"
                        onChange={this.handleChange}
                      >
                        {this.options48.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Klient będzie miał możliwość usunięcia rezerwacji opłaconej
                  z karnetu. Środki z rezerwacji wróca na karnet.
                </font>
              </Table>

              <br />

              <br />
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        Rezerwacja - płatność w klubie
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Możliwość usuwania rezerwacji przez klientów</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentDeleteReservationWithPaymentInClub}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.deleteReservationWithPaymentInClub}
                        id="deleteReservationWithPaymentInClub"
                        // disabled="false"
                        name="deleteReservationWithPaymentInClub"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ile godziny przed rozpoczęciem klient może usunąć
                      rezerwację
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentDeleteReservationWithPaymentInClubMaxHourBeforeStart
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .deleteReservationWithPaymentInClubMaxHourBeforeStart
                        }
                        id="deleteReservationWithPaymentInClubMaxHourBeforeStart"
                        name="deleteReservationWithPaymentInClubMaxHourBeforeStart"
                        onChange={this.handleChange}
                      >
                        {/* <option>{this.courtDefaultValue}</option>*/}
                        {this.options48.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Przy usuwaniu rezerwacji onlina pojawi się dodatkowy
                  przycisk który umożliwi zwrot środków za rezerwację na karnet
                  klienta. Jeśli karnet nie istnieje (lub istniejące nie są już
                  aktywne) utworzony zostanie nowy
                </font>
                <br />
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  ** Jeżeli klient będzie posiadał aktywny karnet w systemie,
                  będzie mógł go użyć przy tworzeniu nowej rezerwacji online.
                  Jeśli środków na karnecie będzie mniej niż wynosi wartość
                  rezerwacji zostanie poproszony o dopłatę
                </font>
              </Table>

              <br />
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        Rezerwacja - utworzone przez recepcję
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Możliwość usuwania rezerwacji klientów, nie opłaconych,
                      utworzonych przez recepcję
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentDeleteReservationCreatedByReception}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.deleteReservationCreatedByReception
                        }
                        id="deleteReservationCreatedByReception"
                        // disabled="false"
                        name="deleteReservationCreatedByReception"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ile godziny przed rozpoczęciem klient może usunąć
                      rezerwację
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentdeleteReservationCreatedByReceptionMaxHourBeforeStart
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .deleteReservationCreatedByReceptionMaxHourBeforeStart
                        }
                        id="deleteReservationCreatedByReceptionMaxHourBeforeStart"
                        name="deleteReservationCreatedByReceptionMaxHourBeforeStart"
                        onChange={this.handleChange}
                      >
                        {/* <option>{this.courtDefaultValue}</option>*/}
                        {this.options48.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Jeżeli włączona jest opcja pokazywania wszystkich rezerwacji
                  uzytkownika, będzię on również w aplikacji widział rezerwację
                  utworzonę przez recepcję przypisane do jego numeru telefonu.
                </font>
              </Table>

              <br />
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>Komunikaty dla klientów</b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      [TENIS] Komunikat dla klienta podczas rezerwacji online
                      <br /> i płatności online (MAKSYMALNIE 200 ZNAKÓW)
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentMsgToClientWhenReservationWithOnlinePayment
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="textarea"
                        size="sm"
                        maxLength={200}
                        onChange={this.handleChange}
                        value={
                          this.postData
                            .msgToClientWhenReservationWithOnlinePayment
                        }
                        id="msgToClientWhenReservationWithOnlinePayment"
                        name="msgToClientWhenReservationWithOnlinePayment"
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      [SQUASH] Komunikat dla klienta podczas rezerwacji online
                      <br /> i płatności online (MAKSYMALNIE 200 ZNAKÓW)
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentMsgToClientWhenReservationWithOnlinePaymentSquash
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="textarea"
                        size="sm"
                        maxLength={200}
                        onChange={this.handleChange}
                        value={
                          this.postData
                            .msgToClientWhenReservationWithOnlinePaymentSquash
                        }
                        id="msgToClientWhenReservationWithOnlinePaymentSquash"
                        name="msgToClientWhenReservationWithOnlinePaymentSquash"
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      [PADEL] Komunikat dla klienta podczas rezerwacji online
                      <br /> i płatności online (MAKSYMALNIE 200 ZNAKÓW)
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentMsgToClientWhenReservationWithOnlinePaymentPadel
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="textarea"
                        size="sm"
                        maxLength={200}
                        onChange={this.handleChange}
                        value={
                          this.postData
                            .msgToClientWhenReservationWithOnlinePaymentPadel
                        }
                        id="msgToClientWhenReservationWithOnlinePaymentPadel"
                        name="msgToClientWhenReservationWithOnlinePaymentPadel"
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Komunikat pojawi się na ostatnim ekranie podsumowania.
                </font>
              </Table>

              <br />
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>Wysyłka wiadomości E-mail</b>
                      <br />
                      Jeśli chcesz otrzymywać maile w wybranych sytuacjach
                      uzupełnij pola poniżej
                      <br />
                      Można podać kilka adresów oddzielając je przecinkami, np:
                      <br />
                      damian@rezerwujkort.pl,kontakt@rezerwujkort
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Utworzenie nowej rezerwacji Online</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentMailOnlineReservationCreate}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="mailOnlineReservationCreate"
                        name="mailOnlineReservationCreate"
                        // disabled={this.props.trainer}
                        // value={this.postData.mailOnlineReservationCreate || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Potwierdznie płatności rezerwacji Online.</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentMailOnlineReservationConfirmPayment}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="mailOnlineReservationConfirmPayment"
                        name="mailOnlineReservationConfirmPayment"
                        // disabled={this.props.trainer}
                        //value={this.postData.mailOnlineReservationConfirmPayment || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Usunięcie rezerwacji online.</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentMailOnlineReservationConfirmPaymentAfterDeleteReservation
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="mailOnlineReservationConfirmPaymentAfterDeleteReservation"
                        name="mailOnlineReservationConfirmPaymentAfterDeleteReservation"
                        // disabled={this.props.trainer}
                        // value={this.postData.mailOnlineReservationConfirmPaymentAfterDeleteReservation || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Potwierdzenie płatności po usunięciu rezerwacji Online{" "}
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentMailOnlineReservationDeleteUnpaidReservation
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="mailOnlineReservationDeleteUnpaidReservation"
                        name="mailOnlineReservationDeleteUnpaidReservation"
                        // disabled={this.props.trainer}
                        // value={this.postData.mailOnlineReservationDeleteUnpaidReservation || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="profile" title="Płatności online">
              <p
                style={{
                  padding: "20px",
                }}
              >
                Aby móc korzystać z płatności za rezerwacje tworzone na stronie
                internetowej oraz aplikacji mobilnej <br />
                należy utworzyć konto w https://www.przelewy24.pl oraz uzupełnić
                dane poniżej.
              </p>
              <Table
                bordered
                style={{
                  marginTop: "10px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>Płatności online</b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b>Edytuj</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Kod Crc.</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentPaymentApiCrc}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="paymentApiCrc"
                        name="paymentApiCrc"
                        // disabled={this.props.trainer}
                        // value={this.postData.paymentApiCrc || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Login aktualnie.</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentPaymentApiLogin}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="paymentApiLogin"
                        name="paymentApiLogin"
                        // disabled={this.props.trainer}
                        //value={this.postData.paymentApiLogin || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Password.</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentPaymentApiPassword}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="paymentApiPassword"
                        name="paymentApiPassword"
                        // disabled={this.props.trainer}
                        // value={this.postData.paymentApiPassword || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Merchant Id </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentPaymentApiMerchantId}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="paymentApiMerchantId"
                        name="paymentApiMerchantId"
                        // disabled={this.props.trainer}
                        // value={this.postData.paymentApiMerchantId || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
              </Table>
              


              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        [TENIS] Możliwość dzielenia płatności podczas rezerwacji online.
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Czy klienci mogą dzielić płatność na kilka osób podczas rezerwacji:*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentTennis}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.splitReservationPaymentTennis
                        }
                        id="splitReservationPaymentTennis"
                        // disabled="false"
                        name="splitReservationPaymentTennis"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Sposób w jaki dzielona płatność ma być realizowana:**
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentTypeTennis === "PAYMENT_FROM_ONE_USER" ? "Płatność wymagana od rezerwującego" : "Płatność wymagana od wszystkich"}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.splitReservationPaymentTypeTennis
                        }
                        id="splitReservationPaymentTypeTennis"
                        // disabled="false"
                        name="splitReservationPaymentTypeTennis"
                        disabled
                      >
                        <option value="PAYMENT_FROM_EVERYONE">Płatność wymagana od wszystkich</option>
                        <option value="PAYMENT_FROM_ONE_USER">Płatność wymagana od rezerwującego</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Włącz możliwość dzielenia płatności tylko dla wybranych numerów telefonów:***</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentForPhoneNumbersTennis}
                      </p>
                    </td>
                    <td>
                    <Form.Group>
                    <Form.Control
                      size="sm"
                      id="splitReservationPaymentForPhoneNumbersTennis"
                      name="splitReservationPaymentForPhoneNumbersTennis"
                      value={this.postData.splitReservationPaymentForPhoneNumbersTennis}
                      onChange={this.handleChangeSplitReservationPaymentForPhoneNumbersTennis}
                      maxLength={255} // Ustawienie maksymalnej długości
                    />
                    {this.state.error && <div style={{ color: 'red' }}>{this.state.error}</div>}
                  </Form.Group>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Ile godziny przed rozpoczęciem rezerwacji można dzielić płatność.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyHoursBeforeReservationTennis
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .splitReservationPaymentHowManyHoursBeforeReservationTennis
                        }
                        id="splitReservationPaymentHowManyHoursBeforeReservationTennis"
                        name="splitReservationPaymentHowManyHoursBeforeReservationTennis"
                        onChange={this.handleChange}
                      >
                        {this.options48.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      Ile czasu na opłacenie swojej części rezerwacji w minutach mają osoby które zostały dołączone do rezerwacji.
                      (Maksymalnie 99 minut)
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyTimeToPayInMinute
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="splitReservationPaymentHowManyTimeToPayInMinute"
                        name="splitReservationPaymentHowManyTimeToPayInMinute"
                        // disabled={this.props.trainer}
                        // value={this.currentOnlineReservationRegulationsUrl || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      Ile osób może dołączyć do rezerwacji osoba rezerwująca.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyPeopleTennis
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .splitReservationPaymentHowManyPeopleTennis
                        }
                        id="splitReservationPaymentHowManyPeopleTennis"
                        name="splitReservationPaymentHowManyPeopleTennis"
                        onChange={this.handleChange}
                      >
                        {this.splitPaymentHowManyPeople.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>

                  * Jeżeli funkcjonalność będzie uruchomiona, podczas płatności za rezerwację klient będzie mógł podzielić płatność za rezerwację na kilka osób.
                  Podaje imiona osób z którymi dzieli płatność oraz ich adresy email. Kiedy opłaci swoją część do pozostałych osób wysłana zostanie wiadomość z linkiem do płatności.
                  Kiedy wszyscy opłacą swoją część rezerwacja zostanie potwierdzona. Jeśli chociaż jedna osoba nie opłaci w wyznaczonym terminie rezerwacja zostanie usunięta a środki zwrócone osobą które opłaciły swoją część.<br/>
                  ** "Płatność wymagana od wszystkich" oznacza, że w wyznaczonym terminie (pole: "Ile czasu na opłacenie swojej części rezerwacji w minutach...") wszystkie osoby muszą opłacić swoją cześć rezerwacji.
                  "Płatność wymagana od rezerwującego" oznacza, że osoba która tworzy rezerwację musi opłacić swoją część w wyznaczonym czasie a pozostałe zaproszone
                  osoby mogę opłacić swoją część w dowolnym momencie aż do zakończenia rezerwacji.<br/>
                  *** Na potrzeby testów można uruchomić funkcjonalność tylko dla wybranych numerów telefonów. Jeśli chcemy wprowadzić kilka numerów oddzielamy je przecinkami np. 507507507,401401401
                </font>
              </Table>

              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        [SQUASH] Możliwość dzielenia płatności podczas rezerwacji online.
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Czy klienci mogą dzielić płatność na kilka osób podczas rezerwacji:*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentSquash}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.splitReservationPaymentSquash
                        }
                        id="splitReservationPaymentSquash"
                        // disabled="false"
                        name="splitReservationPaymentSquash"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Sposób w jaki dzielona płatność ma być realizowana:**
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentTypeSquash === "PAYMENT_FROM_ONE_USER" ? "Płatność wymagana od rezerwującego" : "Płatność wymagana od wszystkich"}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.splitReservationPaymentTypeSquash
                        }
                        id="splitReservationPaymentTypeSquash"
                        // disabled="false"
                        name="splitReservationPaymentTypeSquash"
                        disabled
                      >
                        <option value="PAYMENT_FROM_EVERYONE">Płatność wymagana od wszystkich</option>
                        <option value="PAYMENT_FROM_ONE_USER">Płatność wymagana od rezerwującego</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Włącz możliwość dzielenia płatności tylko dla wybranych numerów telefonów:***</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentForPhoneNumbersSquash}
                      </p>
                    </td>
                    <td>
                    <Form.Group>
                    <Form.Control
                      size="sm"
                      id="splitReservationPaymentForPhoneNumbersSquash"
                      name="splitReservationPaymentForPhoneNumbersSquash"
                      value={this.postData.splitReservationPaymentForPhoneNumbersSquash}
                      onChange={this.handleChangeSplitReservationPaymentForPhoneNumbersSquash}
                      maxLength={255} // Ustawienie maksymalnej długości
                    />
                    {this.state.error && <div style={{ color: 'red' }}>{this.state.error}</div>}
                  </Form.Group>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Ile godziny przed rozpoczęciem rezerwacji można dzielić płatność.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyHoursBeforeReservationSquash
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .splitReservationPaymentHowManyHoursBeforeReservationSquash
                        }
                        id="splitReservationPaymentHowManyHoursBeforeReservationSquash"
                        name="splitReservationPaymentHowManyHoursBeforeReservationSquash"
                        onChange={this.handleChange}
                      >
                        {this.options48.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      Ile czasu na opłacenie swojej części rezerwacji w minutach mają osoby które zostały dołączone do rezerwacji.
                      (Maksymalnie 99 minut)
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyTimeToPayInMinuteSquash
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="splitReservationPaymentHowManyTimeToPayInMinuteSquash"
                        name="splitReservationPaymentHowManyTimeToPayInMinuteSquash"
                        // disabled={this.props.trainer}
                        // value={this.currentOnlineReservationRegulationsUrl || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      Ile osób może dołączyć do rezerwacji osoba rezerwująca.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyPeopleSquash
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .splitReservationPaymentHowManyPeopleSquash
                        }
                        id="splitReservationPaymentHowManyPeopleSquash"
                        name="splitReservationPaymentHowManyPeopleSquash"
                        onChange={this.handleChange}
                      >
                        {this.splitPaymentHowManyPeople.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>

                  * Jeżeli funkcjonalność będzie uruchomiona, podczas płatności za rezerwację klient będzie mógł podzielić płatność za rezerwację na kilka osób.
                  Podaje imiona osób z którymi dzieli płatność oraz ich adresy email. Kiedy opłaci swoją część do pozostałych osób wysłana zostanie wiadomość z linkiem do płatności.
                  Kiedy wszyscy opłacą swoją część rezerwacja zostanie potwierdzona. Jeśli chociaż jedna osoba nie opłaci w wyznaczonym terminie rezerwacja zostanie usunięta a środki zwrócone osobą które opłaciły swoją część.<br/>
                  ** "Płatność wymagana od wszystkich" oznacza, że w wyznaczonym terminie (pole: "Ile czasu na opłacenie swojej części rezerwacji w minutach...") wszystkie osoby muszą opłacić swoją cześć rezerwacji.
                  "Płatność wymagana od rezerwującego" oznacza, że osoba która tworzy rezerwację musi opłacić swoją część w wyznaczonym czasie a pozostałe zaproszone
                  osoby mogę opłacić swoją część w dowolnym momencie aż do zakończenia rezerwacji.<br/>
                  *** Na potrzeby testów można uruchomić funkcjonalność tylko dla wybranych numerów telefonów. Jeśli chcemy wprowadzić kilka numerów oddzielamy je przecinkami np. 507507507,401401401
                </font>
              </Table>

              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>
                        [PADEL] Możliwość dzielenia płatności podczas rezerwacji online.
                      </b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b></b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Czy klienci mogą dzielić płatność na kilka osób podczas rezerwacji:*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentPadel}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.splitReservationPaymentPadel
                        }
                        id="splitReservationPaymentPadel"
                        // disabled="false"
                        name="splitReservationPaymentPadel"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Sposób w jaki dzielona płatność ma być realizowana:**
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentTypePadel === "PAYMENT_FROM_ONE_USER" ? "Płatność wymagana od rezerwującego" : "Płatność wymagana od wszystkich"}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.splitReservationPaymentTypePadel
                        }
                        id="splitReservationPaymentTypePadel"
                        // disabled="false"
                        name="splitReservationPaymentTypePadel"
                        disabled
                      >
                        <option value="PAYMENT_FROM_EVERYONE">Płatność wymagana od wszystkich</option>
                        <option value="PAYMENT_FROM_ONE_USER">Płatność wymagana od rezerwującego</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Włącz możliwość dzielenia płatności tylko dla wybranych numerów telefonów:***</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentSplitReservationPaymentForPhoneNumbersPadel}
                      </p>
                    </td>
                    <td>
                    <Form.Group>
                    <Form.Control
                      size="sm"
                      id="splitReservationPaymentForPhoneNumbersPadel"
                      name="splitReservationPaymentForPhoneNumbersPadel"
                      value={this.postData.splitReservationPaymentForPhoneNumbersPadel}
                      onChange={this.handleChangeSplitReservationPaymentForPhoneNumbersPadel}
                      maxLength={255} // Ustawienie maksymalnej długości
                    />
                    {this.state.error && <div style={{ color: 'red' }}>{this.state.error}</div>}
                  </Form.Group>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Ile godziny przed rozpoczęciem rezerwacji można dzielić płatność.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyHoursBeforeReservationPadel
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .splitReservationPaymentHowManyHoursBeforeReservationPadel
                        }
                        id="splitReservationPaymentHowManyHoursBeforeReservationPadel"
                        name="splitReservationPaymentHowManyHoursBeforeReservationPadel"
                        onChange={this.handleChange}
                      >
                        {this.options48.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      Ile czasu na opłacenie swojej części rezerwacji w minutach mają osoby które zostały dołączone do rezerwacji.
                      (Maksymalnie 99 minut)
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyTimeToPayInMinutePadel
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="splitReservationPaymentHowManyTimeToPayInMinutePadel"
                        name="splitReservationPaymentHowManyTimeToPayInMinutePadel"
                        // disabled={this.props.trainer}
                        // value={this.currentOnlineReservationRegulationsUrl || ''}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      Ile osób może dołączyć do rezerwacji osoba rezerwująca.
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentSplitReservationPaymentHowManyPeoplePadel
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .splitReservationPaymentHowManyPeoplePadel
                        }
                        id="splitReservationPaymentHowManyPeoplePadel"
                        name="splitReservationPaymentHowManyPeoplePadel"
                        onChange={this.handleChange}
                      >
                        {this.splitPaymentHowManyPeople.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>

                  * Jeżeli funkcjonalność będzie uruchomiona, podczas płatności za rezerwację klient będzie mógł podzielić płatność za rezerwację na kilka osób.
                  Podaje imiona osób z którymi dzieli płatność oraz ich adresy email. Kiedy opłaci swoją część do pozostałych osób wysłana zostanie wiadomość z linkiem do płatności.
                  Kiedy wszyscy opłacą swoją część rezerwacja zostanie potwierdzona. Jeśli chociaż jedna osoba nie opłaci w wyznaczonym terminie rezerwacja zostanie usunięta a środki zwrócone osobą które opłaciły swoją część.<br/>
                  ** "Płatność wymagana od wszystkich" oznacza, że w wyznaczonym terminie (pole: "Ile czasu na opłacenie swojej części rezerwacji w minutach...") wszystkie osoby muszą opłacić swoją cześć rezerwacji.
                  "Płatność wymagana od rezerwującego" oznacza, że osoba która tworzy rezerwację musi opłacić swoją część w wyznaczonym czasie a pozostałe zaproszone
                  osoby mogę opłacić swoją część w dowolnym momencie aż do zakończenia rezerwacji.<br/>
                  *** Na potrzeby testów można uruchomić funkcjonalność tylko dla wybranych numerów telefonów. Jeśli chcemy wprowadzić kilka numerów oddzielamy je przecinkami np. 507507507,401401401
                </font>
              </Table>





            </Tab>

            <Tab eventKey="trainers" title="Trenerzy">
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>Trenerzy</b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b>Edytuj</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Możliwość podglądu rezerwacji przez trenerów klubowych*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentTrainerReservationShow}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.trainerReservationShow}
                        id="trainerReservationShow"
                        // disabled="false"
                        name="trainerReservationShow"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Możliwość usuwania rezerwacji przez trenerów klubowych**
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentTrainerReservationDelete}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.trainerReservationDelete}
                        id="trainerReservationDelete"
                        // disabled="true"
                        name="trainerReservationDelete"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ile godziny przed rozpoczęciem trener może usunąć
                      rezerwację{" "}
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentTrainerReservationDeleteMaxHourBeforeStart}
                      </p>
                    </td>
                    <td>
                      {/* <Form.Control
                                    size="sm"
                                    id="trainerReservationDeleteMaxHourBeforeStart"
                                    name="trainerReservationDeleteMaxHourBeforeStart"
                                    onChange={this.handleChange}
                                /> */}
                      <Form.Control
                        as="select"
                        value={
                          this.postData
                            .trainerReservationDeleteMaxHourBeforeStart
                        }
                        id="trainerReservationDeleteMaxHourBeforeStart"
                        name="trainerReservationDeleteMaxHourBeforeStart"
                        onChange={this.handleChange}
                      >
                        {/* <option>{this.courtDefaultValue}</option>*/}
                        {this.options48.map((newsOne) => (
                          <option value={newsOne.value}>{newsOne.label}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Jeżeli opcja będzie aktywna trener po zalogowaniu do system
                  zobaczy na grafiku oraz w histori rezerwacji rezerwacje w
                  ktorych dodany jest jako trener. Aby rezerwacje były widoczne
                  w panelu Trenerzy należy edytować trenera oraz podać adres
                  email którego trener używa logując się do systemu.
                </font>
                <br />
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  ** Jeżeli opcja będzie aktywna trener po zalogowaniu do
                  systemu będzie miał możliwość usunięcia rezerwacji do której
                  został przypisany
                </font>
              </Table>
            </Tab>

            <Tab eventKey="tickets" title="Karnety">
              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}>Karnety</b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b>Edytuj</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Możliwość doładowywania karnetów przez użytkowników*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentTicketValueUpdateByUser}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.ticketValueUpdateByUser}
                        id="ticketValueUpdateByUser"
                        // disabled="false"
                        name="ticketValueUpdateByUser"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>

                  <tr>
                    <td>Minimalna kwota doładowania w złotych</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentTicketValueUpdateByUserMinValue}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="ticketValueUpdateByUserMinValue"
                        name="ticketValueUpdateByUserMinValue"
                        value={this.postData.ticketValueUpdateByUserMinValue}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>Maksymalna kwota doładowania w złotych</td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentTicketValueUpdateByUserMaxValue}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        id="ticketValueUpdateByUserMaxValue"
                        name="ticketValueUpdateByUserMaxValue"
                        value={this.postData.ticketValueUpdateByUserMaxValue}
                        onChange={this.handleChange}
                      />
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Jeżeli opcja będzie aktywna użytkownik będzie mógł zwiększyć
                  wartość karnetu robiąc płatność przez przelewy24.
                </font>
                <br />
                <font
                  style={{ fontStyle: "italic", "font-size": "13px" }}
                ></font>
              </Table>

              <Table
                bordered
                style={{
                  marginTop: "40px",
                  width: "70%",
                  marginLeft: "20px",
                  lineHeight: "25px",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <b style={{ color: "red" }}></b>
                    </th>
                    <th>
                      <b>Aktualnie</b>
                    </th>
                    <th>
                      <b>Nowa wartość</b>
                    </th>
                    <th>
                      <b>Edytuj</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Możliwość płacenia karnetem pienieżnym na kort przy
                      tworzeniu rezerwacji online*
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentReservationOnlinePayedFromUserTicket}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData.reservationOnlinePayedFromUserTicket
                        }
                        id="reservationOnlinePayedFromUserTicket"
                        //disabled="false"
                        name="reservationOnlinePayedFromUserTicket"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Możliwość dopłacenia jeśli na karnecie jest mniej środków
                      niż wynosi rezerwacja**
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {
                          this
                            .currentReservationOnlinePaidToTicketAndMakeReservation
                        }
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={
                          this.postData
                            .reservationOnlinePaidToTicketAndMakeReservation
                        }
                        id="reservationOnlinePaidToTicketAndMakeReservation"
                        //disabled="false"
                        name="reservationOnlinePaidToTicketAndMakeReservation"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Połączenie wszystkich karnetów klienta po numerze
                      telefonu***
                    </td>
                    <td>
                      <p style={{ fontStyle: "italic" }}>
                        {" "}
                        {this.currentConnectClientTicketsByPhone}
                      </p>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={this.handleChange}
                        value={this.postData.connectClientTicketsByPhone}
                        id="connectClientTicketsByPhone"
                        //disabled="false"
                        name="connectClientTicketsByPhone"
                      >
                        <option value="false">Nie</option>
                        <option value="true">Tak</option>
                      </Form.Control>
                    </td>
                    <td>
                      {this.state.btnEditLoader ? btnEditLoaderShow : edit}
                    </td>
                  </tr>
                </tbody>
                <br />
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  * Jeżeli klient będzie posiadał aktywny karnet w systemie,
                  będzie mógł go użyć przy tworzeniu nowej rezerwacji online.
                  Jeśli środków na karnecie będzie mniej niż wynosi wartość
                  rezerwacji zostanie poproszony o dopłatę. Dodatkowo będzie
                  widział wszystkie przypisane do niego aktywne karnety
                </font>
                <br />
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  ** Jeżeli opcja będzie aktywna i podczas rezerwacji kwota na
                  karnecie nie będzie wystarczająca aby pokryć rezerwację,
                  klient zostanie przekierowany na panel płatnośći w celu
                  uzupełnienia wartości karnetu do wysokości kwoty rezerwacji
                  poczym kort zostanie zarezerwowany.
                  <br />
                  Jeśli opcja będzie nie aktywn a środki na karnecie będą niższe
                  niż kwota rezerwacji klient zobaczy komunikat, że na karnecie
                  nie ma wystarczającej ilości środków i nie może zrobić
                  rezerwacji z karnetu.
                </font>
                <br />
                <font style={{ fontStyle: "italic", "font-size": "13px" }}>
                  *** Jeżeli opcja będzie aktywna klient poza karnetami
                  przypisanymi dokładnie do jego konta zobaczy również karnety
                  przypiane do kont o takim samym numerze telefonu. Opcja
                  potrzebna jeśli w systemie istnieje więcej niż jedno konto
                  użytkownika. Np. jedno utworoznoe przez klienta podczas
                  rezerwacji online, drugie utworzone przez recepcję i oba mają
                  ten sam numer telefonu.
                </font>
                <br />
                <font
                  style={{ fontStyle: "italic", "font-size": "13px" }}
                ></font>
              </Table>
            </Tab>
          </Tabs>

          <br />

          <br />
        </>
      );
    }

    return (
      <div>
        {this.state.addCourtPopupForm}

        <div id="content">
          <BeltReservationApp club={this.props.match.params.club} />

          <br />
          <br />
          <h4 style={{ textAlign: "center" }}>Ustawienia programu:</h4>

          {loader}
        </div>
      </div>
    );
  }
}
export default PageSettings;

const regExp = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);

class ParseUtilsToField {
  availableHoursSelectField(utilsData) {
    var availableHours;
    if (utilsData.availableHours !== null) {
      if (typeof utilsData.availableHours.map === "function") {
        availableHours = utilsData.availableHours.map((oneRecord) => (
          <option value={oneRecord.hour}>{oneRecord.hour}</option>
        ));
      } else {
        availableHours = "";
      }
    }
    return availableHours;
  }

  availableReservationHoursAndPriceSelectField(utilsData) {
    var availableHours;
    if (utilsData !== null && utilsData.availableHours !== null) {
      if (typeof utilsData.map === "function") {
        availableHours = utilsData.map((oneRecord) => (
          <option value={oneRecord.hourStopName}>
            {oneRecord.hourStopName} {oneRecord.howLong} Cena: {oneRecord.price}zł
          </option>
        ));
      } else {
        availableHours = "";
      }
    }
    return availableHours;
  }

  availableDatesSelectField(utilsData) {
    var availableDates;
    if (utilsData.availableDates !== null) {
      if (typeof utilsData.availableDates.map === "function") {
        availableDates = utilsData.availableDates.map((oneRecord) => (
          <option value={oneRecord.name}>{oneRecord.name}</option>
        ));
      } else {
        availableDates = "";
      }
    }
    return availableDates;
  }

  availableDatesMultiSelectField(utilsData) {
    var availableDates;
    if (utilsData.availableDates !== null) {
      if (typeof utilsData.availableDates.map === "function") {
        availableDates = utilsData.availableDates.map((oneRecord) => ({
          value: oneRecord.id,
          label: oneRecord.name,
        }));
        // <option
        //     value={oneRecord.name}>{oneRecord.name}</option>
      } else {
        availableDates = "";
      }
    }
    return availableDates;
  }

  priceListTypesMultiSelectField(utilsData) {
    var priceListTypes;
    if (utilsData.priceListTypes !== null) {
      if (typeof utilsData.priceListTypes.map === "function") {
        priceListTypes = utilsData.priceListTypes.map((oneRecord) => ({
          value: oneRecord.id,
          label: oneRecord.name,
        }));
        // <option
        //     value={oneRecord.name}>{oneRecord.name}</option>
      } else {
        priceListTypes = "";
      }
    }
    return priceListTypes;
  }

  salesChannelsMultiSelectField(utilsData) {
    var salesChannels;
    if (utilsData.salesChannels !== null) {
      if (typeof utilsData.salesChannels.map === "function") {
        salesChannels = utilsData.salesChannels.map((oneRecord) => ({
          value: oneRecord.id,
          label: oneRecord.name,
        }));
        // <option
        //     value={oneRecord.name}>{oneRecord.name}</option>
      } else {
        salesChannels = "";
      }
    }
    return salesChannels;
  }

  availableDaysOfWeekMultiSelectField(utilsData) {
    var availableDays;
    if (utilsData.availableDays !== null) {
      if (typeof utilsData.availableDays.map === "function") {
        availableDays = utilsData.availableDays.map((oneRecord) => ({
          value: oneRecord.value,
          label: oneRecord.name,
        }));
        // <option
        //     value={oneRecord.name}>{oneRecord.name}</option>
      } else {
        availableDays = "";
      }
    }
    return availableDays;
  }

  availableMonthMultiSelectField(utilsData) {
    var availableMonth;
    if (utilsData.availableMonth !== null) {
      if (typeof utilsData.availableMonth.map === "function") {
        availableMonth = utilsData.availableMonth.map((oneRecord) => (
          <option value={oneRecord.value}>{oneRecord.name}</option>
        ));
      } else {
        availableMonth = "";
      }
    }
    return availableMonth;
  }

  availableDaysSelectField(utilsData) {
    var availableDays;
    if (utilsData.availableDays !== null) {
      if (typeof utilsData.availableDays.map === "function") {
        availableDays = utilsData.availableDays.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        availableDays = "";
      }
    }
    return availableDays;
  }

  searchBySelectField(utilsData) {
    var searchBy;
    if (utilsData.searchBy !== null) {
      if (typeof utilsData.searchBy.map === "function") {
        searchBy = utilsData.searchBy.map((oneRecord) => (
          <option value={oneRecord.value}>{oneRecord.name}</option>
        ));
      } else {
        searchBy = "";
      }
    }
    return searchBy;
  }

  availableTrainersSelectField(utilsData) {
    var trainers;
    if (utilsData.trainers !== null) {
      if (typeof utilsData.trainers.map === "function") {
        trainers = utilsData.trainers.map((oneRecord) => (
          <option value={oneRecord.id}>
            {oneRecord.surname}&nbsp;{oneRecord.name}
          </option>
        ));
      } else {
        trainers = "";
      }
    }
    return trainers;
  }

  paymentMethodsSelectField(utilsData) {
    var paymentMethods;
    if (utilsData.paymentMethods !== null) {
      if (typeof utilsData.paymentMethods.map === "function") {
        paymentMethods = utilsData.paymentMethods.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        paymentMethods = "";
      }
    }
    return paymentMethods;
  }

  courtListSelectField(utilsData) {
    var courtList;
    if (utilsData.courts !== null) {
      if (typeof utilsData.courts.map === "function") {
        courtList = utilsData.courts.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        courtList = "";
      }
    }
    return courtList;
  }

  sportListSelectField(utilsData) {
    var sportList;
    if (utilsData.sport !== null) {
      if (typeof utilsData.sport.map === "function") {
        sportList = utilsData.sport.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        sportList = "";
      }
    }
    return sportList;
  }

  reservationTypeSelectField(utilsData) {
    var reservationType;
    if (utilsData.reservationType !== null) {
      if (typeof utilsData.reservationType.map === "function") {
        reservationType = utilsData.reservationType.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        reservationType = "";
      }
    }
    return reservationType;
  }

  promotionsTypeSelectField(utilsData) {
    var promotions;
    if (utilsData.promotions !== null) {
      if (typeof utilsData.promotions.map === "function") {
        promotions = utilsData.promotions.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        promotions = "";
      }
    }
    return promotions;
  }

  courtListMultiSelectField(utilsData) {
    var courtList;
    if (utilsData.courts !== null) {
      if (typeof utilsData.courts.map === "function") {
        courtList = utilsData.courts.map((oneRecord) => ({
          value: oneRecord.id,
          label: oneRecord.name,
        }));
      } else {
        courtList = "";
      }
    }
    return courtList;
  }

  ticketsCourtSelectField(utilsData) {
    var ticketsCourt;
    if (utilsData.ticketsCourt !== null) {
      if (typeof utilsData.ticketsCourt.map === "function") {
        ticketsCourt = utilsData.ticketsCourt.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        ticketsCourt = "";
      }
    }
    return ticketsCourt;
  }

  ticketsTrainerSelectField(utilsData) {
    var ticketsTrainer;
    if (utilsData.ticketsTrainer !== null) {
      if (typeof utilsData.ticketsTrainer.map === "function") {
        ticketsTrainer = utilsData.ticketsTrainer.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        ticketsTrainer = "";
      }
    }
    return ticketsTrainer;
  }

  sortBySelectField(utilsData) {
    var sortBy;
    if (utilsData.sortBy !== null) {
      if (typeof utilsData.sortBy.map === "function") {
        sortBy = utilsData.sortBy.map((oneRecord) => (
          <option value={oneRecord.value}>{oneRecord.name}</option>
        ));
      } else {
        sortBy = "";
      }
    }
    return sortBy;
  }

  ticketStatusSelectField(utilsData) {
    var ticketStatus;
    if (utilsData.ticketStatus !== null) {
      if (typeof utilsData.ticketStatus.map === "function") {
        ticketStatus = utilsData.ticketStatus.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.status}</option>
        ));
      } else {
        ticketStatus = "";
      }
    }
    return ticketStatus;
  }

  promotionStatusSelectField(utilsData) {
    var promotionStatus;
    if (utilsData.promotionStatus !== null) {
      if (typeof utilsData.promotionStatus.map === "function") {
        promotionStatus = utilsData.promotionStatus.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.status}</option>
        ));
      } else {
        promotionStatus = "";
      }
    }
    return promotionStatus;
  }

  salesChannelSelectField(utilsData) {
    var salesChannel;
    if (utilsData.salesChannel !== null) {
      if (typeof utilsData.salesChannel.map === "function") {
        salesChannel = utilsData.salesChannel.map((oneRecord) => (
          <option value={oneRecord.id}>
            {oneRecord.status === undefined ? oneRecord.name : oneRecord.status}
          </option>
        ));
      } else {
        salesChannel = "";
      }
    }
    return salesChannel;
  }

  ticketTypeSelectField(utilsData) {
    var ticketTypes;
    if (utilsData.ticketTypes !== null) {
      if (typeof utilsData.ticketTypes.map === "function") {
        ticketTypes = utilsData.ticketTypes.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        ticketTypes = "";
      }
    }
    return ticketTypes;
  }

  promotionClientsTypeSelectField(utilsData) {
    var promotionClientsType;
    if (utilsData.promotionClientsType !== null) {
      if (typeof utilsData.promotionClientsType.map === "function") {
        promotionClientsType = utilsData.promotionClientsType.map(
          (oneRecord) => <option value={oneRecord.id}>{oneRecord.name}</option>
        );
      } else {
        promotionClientsType = "";
      }
    }
    return promotionClientsType;
  }

  statusSelectField(utilsData) {
    var status;
    if (utilsData.status !== null) {
      if (typeof utilsData.status.map === "function") {
        status = utilsData.status.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.status}</option>
        ));
      } else {
        status = "";
      }
    }
    return status;
  }

  availableTicketsSelectField(utilsData) {
    var availableTickets;
    if (utilsData.availableTickets !== null) {
      if (typeof utilsData.availableTickets.map === "function") {
        availableTickets = utilsData.availableTickets.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        availableTickets = "";
      }
    }
    return availableTickets;
  }

  availableTicketsPriceTypeSelectField(utilsData) {
    var availableTicketsPriceType;
    if (utilsData.availableTicketsPriceType !== null) {
      if (typeof utilsData.availableTicketsPriceType.map === "function") {
        availableTicketsPriceType = utilsData.availableTicketsPriceType.map(
          (oneRecord) => <option value={oneRecord.id}>{oneRecord.name}</option>
        );
      } else {
        availableTicketsPriceType = "";
      }
    }
    return availableTicketsPriceType;
  }

  expirationDateSelectFieldSelectField(utilsData) {
    var expirationDate;
    if (utilsData.expirationDate !== null) {
      if (typeof utilsData.expirationDate.map === "function") {
        expirationDate = utilsData.expirationDate.map((oneRecord) => (
          <option value={oneRecord.id}>{oneRecord.name}</option>
        ));
      } else {
        expirationDate = "";
      }
    }
    return expirationDate;
  }

  clientsField(utilsData) {
    var clients = "";
    if (utilsData.clients !== null) {
      if (typeof utilsData.clients.map === "function") {
        clients = utilsData.clients;
      } else {
        clients = "";
      }

      return clients;
    }
  }

  promotionsField(utilsData) {
    var promotions = "";
    if (utilsData.promotions !== null && utilsData.promotions !== undefined) {
      if (typeof utilsData.promotions.map === "function") {
        promotions = utilsData.promotions;
      } else {
        promotions = "";
      }

      return promotions;
    }
  }

  clientTypeSelectField(utilsData) {
    var clientType;
    if (utilsData.clientType !== null) {
      if (typeof utilsData.clientType.map === "function") {
        clientType = utilsData.clientType.map((oneRecord) => (
          <option value={oneRecord.value}>{oneRecord.label}</option>
        ));
      } else {
        clientType = "";
      }
    }
    return clientType;
  }

  promotionTypeSelectField(utilsData) {
    var promotionType;
    if (utilsData.promotionType !== null) {
      if (typeof utilsData.promotionType.map === "function") {
        promotionType = utilsData.promotionType.map((oneRecord) => (
          <option value={oneRecord.value}>{oneRecord.label}</option>
        ));
      } else {
        promotionType = "";
      }
    }
    return promotionType;
  }

  currentClientChoose(utilsData, clientName, clientId) {
    var currentClientChoose;
    if (utilsData.clients !== null) {
      if (typeof utilsData.clients.map === "function") {
        if (clientName != "") {
          currentClientChoose = utilsData.clients.find(
            (obj) => obj.value === clientId
          );
        } else {
          currentClientChoose = "";
        }
      } else {
        currentClientChoose = "";
      }
    }
    return currentClientChoose;
  }

  currentClientChooseNew(utilsData, clientName, clientId) {
    var currentClientChoose;
    if (utilsData.clients !== null) {
      if (typeof utilsData.clients.map === "function") {
        //if (clientName != "") {
        currentClientChoose = utilsData.clients.find(
          (obj) => obj.value === Number(clientId)
        );
        //} else {
        //    currentClientChoose = "";
        //}
      } else {
        currentClientChoose = "";
      }
    }
    return currentClientChoose;
  }

  currentPromotionChooseNew(utilsData, clientName, clientId) {
    var currentPromotionChoose;
    if (utilsData.promotions !== null && utilsData.promotions !== undefined) {
      if (typeof utilsData.promotions.map === "function") {
        currentPromotionChoose = utilsData.promotions.find(
          (obj) => obj.value === Number(clientId)
        );
      } else {
        currentPromotionChoose = "";
      }
    }
    return currentPromotionChoose;
  }

  dayInMonths() {
    var elements = [];
    for (var i = 1; i <= 31; i++) {
      elements.push(<option value={i}>{i}</option>);
    }
    return elements;
  }
}

export default new ParseUtilsToField();

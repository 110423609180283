import queryString from "query-string";

//metoda parsuje dane zz urla do obiektu ktory przekazujemy i go zwraca
export default function ParseLinkToArray(queryString, postData, deafulValues) {
  if (postData.dateStart != undefined) {
    if (
      queryString.dateStart != undefined &&
      !deafulValues.includes(queryString.dateStart)
    ) {
      postData.dateStart = queryString.dateStart;
    } else {
      postData.dateStart = "";
    }
  }

  if (postData.dateStart != undefined) {
    if (
      queryString.dateStart != undefined &&
      !deafulValues.includes(queryString.dateStart)
    ) {
      postData.dateStart = queryString.dateStart;
    } else {
      postData.dateStart = "";
    }
  }

  if (postData.dateStop != undefined) {
    if (
      queryString.dateStop != undefined &&
      !deafulValues.includes(queryString.dateStop)
    ) {
      postData.dateStop = queryString.dateStop;
    } else {
      postData.dateStop = "";
    }
  }

  if (postData.hourStart != undefined) {
    if (
      queryString.hourStart != undefined &&
      !deafulValues.includes(queryString.hourStart)
    ) {
      postData.hourStart = queryString.hourStart;
    } else {
      postData.hourStart = "";
    }
  }

  if (postData.hourStop != undefined) {
    if (
      queryString.hourStop != undefined &&
      !deafulValues.includes(queryString.hourStop)
    ) {
      postData.hourStop = queryString.hourStop;
    } else {
      postData.hourStop = "";
    }
  }

  if (postData.dayOfWeek != undefined) {
    if (
      queryString.dayOfWeek != undefined &&
      !deafulValues.includes(queryString.dayOfWeek)
    ) {
      postData.dayOfWeek = queryString.dayOfWeek;
    } else {
      postData.dayOfWeek = "";
    }
  }

  if (postData.courtId != undefined) {
    if (
      queryString.courtId != undefined &&
      !deafulValues.includes(queryString.courtId)
    ) {
      postData.courtId = queryString.courtId;
    } else {
      postData.courtId = "";
    }
  }

  if (postData.sportId != undefined) {
    if (
      queryString.sportId != undefined &&
      !deafulValues.includes(queryString.sportId)
    ) {
      postData.sportId = queryString.sportId;
    } else {
      postData.sportId = "";
    }
  }

  if (postData.reservationType != undefined) {
    if (
      queryString.reservationType != undefined &&
      !deafulValues.includes(queryString.reservationType)
    ) {
      postData.reservationType = queryString.reservationType;
    } else {
      postData.reservationType = "";
    }
  }

  if (postData.trainer != undefined) {
    if (
      queryString.trainer != undefined &&
      !deafulValues.includes(queryString.trainer)
    ) {
      postData.trainer = queryString.trainer;
    } else {
      postData.trainer = "";
    }
  }

  if (postData.status != undefined) {
    if (
      queryString.status != undefined &&
      !deafulValues.includes(queryString.status)
    ) {
      postData.status = queryString.status;
    } else {
      postData.status = "";
    }
  }

  if (postData.classId != undefined) {
    if (
      queryString.classId != undefined &&
      !deafulValues.includes(queryString.classId)
    ) {
      postData.classId = queryString.classId;
    } else {
      postData.classId = "";
    }
  }

  if (postData.className != undefined) {
    if (
      queryString.className != undefined &&
      !deafulValues.includes(queryString.className)
    ) {
      postData.className = queryString.className;
    } else {
      postData.className = "";
    }
  }

  if (postData.studentId != undefined) {
    if (
      queryString.studentId != undefined &&
      !deafulValues.includes(queryString.studentId)
    ) {
      postData.studentId = queryString.studentId;
    } else {
      postData.studentId = "";
    }
  }

  if (postData.club != undefined) {
    if (
      queryString.club != undefined &&
      !deafulValues.includes(queryString.club)
    ) {
      postData.club = queryString.club;
    } else {
      postData.club = "";
    }
  }

  if (postData.city != undefined) {
    if (
      queryString.city != undefined &&
      !deafulValues.includes(queryString.city)
    ) {
      postData.city = queryString.city;
    } else {
      postData.city = "";
    }
  }

  if (postData.page != undefined) {
    if (
      queryString.page != undefined &&
      !deafulValues.includes(queryString.page)
    ) {
      var pageTemp = parseInt(queryString.page);
      postData.page = pageTemp - 1;
    } else {
      postData.page = "0";
    }
  }

  if (postData.ids != undefined) {
    if (
      queryString.ids != undefined &&
      !deafulValues.includes(queryString.ids)
    ) {
      postData.ids = queryString.ids.split(",");
    } else {
      postData.ids = "";
    }
  }

  if (postData.clientId != undefined) {
    if (
      queryString.clientId != undefined &&
      !deafulValues.includes(queryString.clientId)
    ) {
      postData.clientId = queryString.clientId;
    } else {
      postData.clientId = "";
    }
  }

  if (postData.clientType != undefined) {
    if (
      queryString.clientType != undefined &&
      !deafulValues.includes(queryString.clientType)
    ) {
      postData.clientType = queryString.clientType;
    } else {
      postData.clientType = "";
    }
  }

  if (postData.sortBy != undefined) {
    if (
      queryString.sortBy != undefined &&
      !deafulValues.includes(queryString.sortBy)
    ) {
      postData.sortBy = queryString.sortBy;
    } else {
      postData.sortBy = "";
    }
  }

  if (postData.ticketStatus != undefined) {
    if (
      queryString.ticketStatus != undefined &&
      !deafulValues.includes(queryString.ticketStatus)
    ) {
      postData.ticketStatus = queryString.ticketStatus;
    } else {
      postData.ticketStatus = "";
    }
  }

  if (postData.ticketType != undefined) {
    if (
      queryString.ticketType != undefined &&
      !deafulValues.includes(queryString.ticketType)
    ) {
      postData.ticketType = queryString.ticketType;
    } else {
      postData.ticketType = "";
    }
  }

  if (postData.searchDirection != undefined) {
    if (
      queryString.searchDirection != undefined &&
      !deafulValues.includes(queryString.searchDirection)
    ) {
      if (queryString.searchDirection == "true") {
        postData.searchDirection = true;
      } else if (queryString.searchDirection == "false") {
        postData.searchDirection = false;
      } else {
        postData.searchDirection = queryString.searchDirection;
      }
    } else {
      postData.searchDirection = false;
    }
  }

  if (postData.ticketUsedMoreThenValue != undefined) {
    if (
      queryString.ticketUsedMoreThenValue != undefined &&
      !deafulValues.includes(queryString.ticketUsedMoreThenValue)
    ) {
      if (queryString.ticketUsedMoreThenValue == "true") {
        postData.ticketUsedMoreThenValue = true;
      } else if (queryString.ticketUsedMoreThenValue == "false") {
        postData.ticketUsedMoreThenValue = false;
      } else {
        postData.ticketUsedMoreThenValue = queryString.ticketUsedMoreThenValue;
      }
    } else {
      postData.ticketUsedMoreThenValue = false;
    }
  }

  if (postData.promotion != undefined) {
    if (
      queryString.promotion != undefined &&
      !deafulValues.includes(queryString.promotion)
    ) {
      postData.promotion = queryString.promotion;
    } else {
      postData.promotion = "";
    }
  }

  if (postData.deleted != undefined) {
    if (
      queryString.deleted != undefined &&
      !deafulValues.includes(queryString.deleted)
    ) {
      postData.deleted = queryString.deleted;
    } else {
      postData.deleted = "";
    }
  }

  if (postData.enabled != undefined) {
    if (
      queryString.enabled != undefined &&
      !deafulValues.includes(queryString.enabled)
    ) {
      postData.enabled = queryString.enabled;
    } else {
      postData.enabled = "";
    }
  }

  if (postData.splitPayment != undefined) {
    if (
      queryString.splitPayment != undefined &&
      !deafulValues.includes(queryString.splitPayment)
    ) {
      postData.splitPayment = queryString.splitPayment;
    } else {
      postData.splitPayment = "";
    }
  }

  return postData;
}

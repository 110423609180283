import React from "react";

import { Spinner } from "react-bootstrap";
import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";

import Footer from "../components/Footer";
import FechGetReservationPayment from "../fechData/FechGetReservationPayment";

import "react-datepicker/dist/react-datepicker.css";

class ClubReservationOnlineConfirmPaymentPage extends React.Component {
  state = {
    loading: true,
    restPaymentStatusData: {
      successful: false,
      status: "",
      message: "",
      data: {},
    },
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    
    let response;
    while (!response) {
      try {
        response = await FechGetReservationPayment(this.props.match.params.token);
      } catch (error) {
        console.error("Błąd pobierania danych, ponawianie...");
      }
    }
    
    // await new Promise(resolve => setTimeout(resolve, 2000));
    
    this.setState({ restPaymentStatusData: response, loading: false }, () => {
      if (response.status === 200 && response.data.url) {
        window.location.href = response.data.url;
      }
    });
  };

  render() {
    const { loading, restPaymentStatusData } = this.state;
    
    return (
      <div>
        <Belt />
        <BeltLogged />
        <div id="content">
          <div className="container">
            <div className="main-subpage tennis-center">
              <div className="main-subpage-content">
                <div className="tenis-center-content">
                  <div className="tennis-center-coach">
                    {loading ? (
                      <>
                        <p>Przetwarzanie płatności</p>
                        <Spinner style={{ borderStyle: "dotted", marginLeft: "50%" }} animation="border" />
                      </>
                    ) : restPaymentStatusData.status !== 200 ? (
                      <p>{restPaymentStatusData.message}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ clear: "both" }}></div>
        <Footer />
      </div>
    );
  }
}

export default ClubReservationOnlineConfirmPaymentPage;
